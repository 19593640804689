import { Routes } from '@angular/router';

import { getCustomersResolver } from '../../shared/services/resolvers/get-customers.resolver';
import { getAllPollsResolver } from '../../shared/services/resolvers/get-poll.resolver';
import {
  getUnitResolver,
  getUnitsResolver,
} from '../../shared/services/resolvers/get-unit.resolver';
import {
  getUserResolver,
  getUsersResolver,
} from '../../shared/services/resolvers/get-user.resolver';
import { CustomersComponent } from './customers/customers.component';
import { RegistrationComponent } from './registration.component';
import { UnitEditComponent } from './units/unit-edit/unit-edit.component';
import { UnitsComponent } from './units/units.component';
import { UserComponent } from './users/user/user.component';
import { UsersComponent } from './users/users.component';

export const registrationRoutes: Routes = [
  {
    path: '',
    loadComponent: () => RegistrationComponent,
    children: [
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full',
      },
      {
        path: 'users',
        title: 'Usuários',
        resolve: {
          users: getUsersResolver,
        },
        loadComponent: () => UsersComponent,
      },
      {
        path: 'new-user',
        title: 'Novo Usuário',
        loadComponent: () => UserComponent,
      },
      {
        path: 'user/:id',
        title: 'Editar Usuário',
        resolve: {
          user: getUserResolver,
        },
        loadComponent: () => UserComponent,
      },
      {
        path: 'customers',
        title: 'Clientes',
        resolve: {
          customers: getCustomersResolver,
        },
        loadComponent: () => CustomersComponent,
      },
      {
        path: 'units',
        title: 'Unidades',
        resolve: {
          units: getUnitsResolver,
        },
        loadComponent: () => UnitsComponent,
      },
      {
        path: 'unit/:id',
        title: 'Editar Unidade',
        resolve: {
          unit: getUnitResolver,
          polls: getAllPollsResolver,
        },
        loadComponent: () => UnitEditComponent,
      },
    ],
  },
];
