<div class="itens">
  <mat-radio-group
    aria-labelledby="example-radio-group-label"
    class="example-radio-group"
    [(ngModel)]="filter().branchOrUser"
  >
    <mat-radio-button class="example-radio-button" [value]="0"
      >CR / Unidade de pesquisa</mat-radio-button
    >

    <!-- Liberar somente quando existir tela de perfis -->
    <!-- <mat-radio-button class="example-radio-button" [value]="1"
      >Perfil de usuario</mat-radio-button
    > -->
  </mat-radio-group>

  <!-- Liberar somente quando existir tela de perfis -->
  <!-- <div class="select">
    <mat-form-field appearance="outline">
      <mat-select [(ngModel)]="filter().profileId">
        <mat-option [value]="'1'">Admin </mat-option>
        <mat-option [value]="'2'">Gerente de unidade </mat-option>
      </mat-select>
    </mat-form-field>
  </div> -->
</div>

@if (filter().branchOrUser === 0){
<div class="user-units">
  <div class="all-units">
    <p>Unidades não consideradas nos resultados:</p>
    <div class="input-icon">
      <input
        type="text"
        class="form-control"
        placeholder="Buscar"
        [formControl]="filterUnits"
      />
      <img src="assets/svg/search.svg" alt="search" />
    </div>

    <div class="units">
      @for (unit of units; track $index) {
      <p
        [ngClass]="{ active: moveToSelected.includes(unit.id) }"
        (click)="onMoveToSelected(unit.id)"
      >
        CR{{ unit.code + " - " + unit.name }}
      </p>
      }
    </div>
  </div>
  <div class="actions">
    <div
      [ngClass]="{
        disabled: !units.length
      }"
      (click)="selectAll()"
    >
      >>
    </div>
    <div
      [ngClass]="{
        disabled: !moveToSelected.length
      }"
      (click)="onSelected()"
    >
      >
    </div>
    <div
      [ngClass]="{
        disabled: !moveToUnselected.length
      }"
      (click)="onUnselected()"
    >
      <
    </div>
    <div
      [ngClass]="{
        disabled: !selectedUnits.length
      }"
      (click)="unselectedAll()"
    >
      <<
    </div>
  </div>
  <div class="selected-units">
    <p>Unidades consideradas nos resultados:</p>
    <div class="input-icon">
      <input
        type="text"
        class="form-control"
        placeholder="Buscar"
        [formControl]="filterSelectedUnits"
      />
      <img src="assets/svg/search.svg" alt="search" />
    </div>

    <div class="units">
      @for (unit of selectedUnits; track $index) {
      <p
        [ngClass]="{
          active: moveToUnselected.includes(unit.id),
          disabled: !moveToUnselected.length
        }"
        (click)="onMoveToUnselected(unit.id)"
      >
        CR{{ unit.code + " - " + unit.name }}
      </p>
      }
    </div>
  </div>
</div>
} @else {
<div class="users">
  <mat-radio-group
    aria-label="Select an User"
    [(ngModel)]="filter().profileUserId"
  >
    <mat-radio-button [value]="'fa9b600b-7aaf-473e-a8a6-e83557353df1'"
      >Option 1 Option 1 Option 1</mat-radio-button
    >
    <mat-radio-button [value]="'fa9b600b-7aaf-473e-a8a6-e83557353df2'"
      >Option 1 Option 1 Option 1</mat-radio-button
    >
    <mat-radio-button [value]="'fa9b600b-7aaf-473e-a8a6-e83557353df3'"
      >Option 1 Option 1 Option 1</mat-radio-button
    >
    <mat-radio-button [value]="'fa9b600b-7aaf-473e-a8a6-e83557353df4'"
      >Option 1 Option 1 Option 1</mat-radio-button
    >
    <mat-radio-button [value]="'fa9b600b-7aaf-473e-a8a6-e83557353df5'"
      >Option 1 Option 1 Option 1</mat-radio-button
    >
  </mat-radio-group>
</div>
}
