import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, Subject, takeUntil } from 'rxjs';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BreadcrumbComponent } from '../../../../shared/components/breadcrumb/breadcrumb.component';
import { ConfirmationModalComponent } from '../../../../shared/components/confirmation-modal/confirmation-modal.component';
import { LoadingService } from '../../../../shared/services/loading.service';
import { UnitsService } from '../../../../shared/services/units.service';
import { UserService } from '../../../../shared/services/user.service';
import { IBreadcrumb } from '../../../../utils/interfaces/breadcrumb';
import { IPollToList } from '../../../../utils/interfaces/poll';
import {
  IAvailableTime,
  IAvailableTimeForm,
  IDevices,
  IDevicesForm,
  IUnit,
  IUnitForm,
  IUserForm,
  IUsersBranch,
} from '../../../../utils/interfaces/unit';
import { IUser } from '../../../../utils/interfaces/user';
import { AlertService } from './../../../../shared/services/alert.service';
import { IDevicesBranch } from './../../../../utils/interfaces/unit';

@Component({
  selector: 'app-unit-edit',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbComponent,
    MatInputModule,
    MatSelectModule,
    MatAutocompleteModule,
  ],
  templateUrl: './unit-edit.component.html',
  styleUrl: './unit-edit.component.scss',
})
export class UnitEditComponent implements OnInit, OnDestroy {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly alertService = inject(AlertService);
  private readonly userService = inject(UserService);
  private readonly dialog = inject(MatDialog);
  private readonly router = inject(Router);
  private readonly unitsService = inject(UnitsService);
  private readonly loadingService = inject(LoadingService);
  private readonly unsubscribe$ = new Subject();

  breadcrumb: IBreadcrumb[] = [
    {
      description: 'Unidades',
      url: 'main/registration/units',
      textTitle: 'Cancelar edição',
      descriptionMsg: 'Deseja realmente cancelar a edição da unidade?',
      askBeforeChangingRoute: true,
    },
    {
      description: 'Detalhes da unidade',
      url: '',
    },
  ];

  form!: FormGroup<IUnitForm>;
  formNewAvailableTime!: FormGroup<IAvailableTimeForm>;
  searchUser = new FormControl<string>('');
  newDevice = new FormControl<string>('', Validators.required);

  usersList: IUser[] = [];

  loadingUsers = false;
  unit!: IUnit;
  polls: IPollToList[] = [];
  isSearching = false;

  ngOnInit(): void {
    this.initialValues();

    this.searchUser.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(debounceTime(700))
      .subscribe((value) => this.filterUsers(value, true));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  initialValues(): void {
    this.unit = this.activatedRoute.snapshot.data['unit'].data;
    this.polls = this.activatedRoute.snapshot.data['polls'].data;

    this.form = new FormGroup<IUnitForm>({
      id: new FormControl<string>(this.unit.id),
      branchId: new FormControl<string>(this.unit.id),
      code: new FormControl<string>({ value: this.unit.code, disabled: true }),
      name: new FormControl<string>({
        value: this.unit.name,
        disabled: true,
      }),
      region: new FormControl<string>({
        value: this.unit.region,
        disabled: true,
      }),
      active: new FormControl<boolean>({
        value: this.unit.active,
        disabled: true,
      }),
      customerName: new FormControl<string>({
        value: this.unit.customerName,
        disabled: true,
      }),
      pollId: new FormControl<string>(
        this.unit.pollId ?? '',
        Validators.required
      ),
      availableShifts: new FormArray<FormGroup<IAvailableTimeForm>>(
        this.unit.availableShifts.map((available: IAvailableTime) =>
          this.createAvailableTime(available)
        )
      ),
      usersBranch: new FormArray<FormGroup<IUserForm>>(
        this.unit.usersBranch.map((user: IUsersBranch) => this.createUser(user))
      ),
      devices: new FormArray<FormGroup<IDevicesForm>>(
        this.unit.devices.map((device: IDevicesBranch) =>
          this.createDevice(device)
        )
      ),
    });

    this.formNewAvailableTime = new FormGroup<IAvailableTimeForm>({
      description: new FormControl<string | null>(null, Validators.required),
      startTime: new FormControl<string | null>(null, Validators.required),
      endTime: new FormControl<string | null>(null, Validators.required),
    });
  }

  createAvailableTime(
    availableTime: IAvailableTime
  ): FormGroup<IAvailableTimeForm> {
    const available = new FormGroup<IAvailableTimeForm>({
      description: new FormControl<string>(
        {
          value: availableTime.description,
          disabled: true,
        },
        { validators: Validators.required }
      ),
      startTime: new FormControl<string>(
        {
          value: availableTime.startTime,
          disabled: true,
        },
        { validators: Validators.required }
      ),
      endTime: new FormControl<string>(
        {
          value: availableTime.endTime,
          disabled: true,
        },
        { validators: Validators.required }
      ),
    });

    if (availableTime.id) {
      available.addControl(
        'id',
        new FormControl<string | null>(availableTime.id)
      );
    }

    return available;
  }

  createUser(user: IUsersBranch): FormGroup<IUserForm> {
    return new FormGroup<IUserForm>({
      userId: new FormControl<string>(user.userId),
      userName: new FormControl<string>({
        value: user.userName,
        disabled: true,
      }),
      userEmail: new FormControl<string>({
        value: user.userEmail,
        disabled: true,
      }),
    });
  }

  createDevice(device: IDevices): FormGroup<IDevicesForm> {
    const deviceForm = new FormGroup<IDevicesForm>({
      name: new FormControl<string>(
        { value: device.name, disabled: true },
        Validators.required
      ),
    });

    if (device.id) {
      deviceForm.addControl('id', new FormControl<string | null>(device.id));
    }

    return deviceForm;
  }

  validateAddAvailableTime(): void {
    if (this.formNewAvailableTime.valid) {
      const newAvailableTime = this.formNewAvailableTime
        .value as IAvailableTime;

      if (
        this.form.controls.availableShifts
          .getRawValue()
          .map((value) => value.description!.toLocaleLowerCase())
          .includes(newAvailableTime.description.toLocaleLowerCase())
      ) {
        this.alertService.danger(
          'Já existe um horário disponível com essa descrição.'
        );

        return;
      }

      this.form.controls.availableShifts.push(
        this.createAvailableTime(newAvailableTime)
      );
      this.formNewAvailableTime.reset();
    } else {
      this.formNewAvailableTime.markAllAsTouched();
    }
  }

  deleteAvailableTime(index: number): void {
    this.form.controls.availableShifts.removeAt(index);
  }

  filterUsers(filter: string | null = null, search = false): void {
    this.loadingUsers = true;
    if (!filter) {
      this.usersList = [];
      this.isSearching = false;
      this.loadingUsers = false;

      return;
    }

    this.userService
      .getUsers(search, filter, 0, 0, true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.usersList = res.data;
        this.isSearching = true;
        this.loadingUsers = false;
      });
  }

  checkAddUser(user: IUser): void {
    if (
      this.form.controls.usersBranch.value.findIndex(
        (item) => item.userId === user.id
      ) !== -1
    ) {
      this.alertService.danger('Esse usuário já foi adicionado.');

      return;
    }

    this.form.controls.usersBranch.push(
      this.createUser({
        userId: user.id,
        userName: user.name,
        userEmail: user.email,
      })
    );
  }

  deleteUser(id: string): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '500px',
      data: {
        title: 'Excluir usuário',
        description: 'Deseja realmente excluir esse usuário?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          const index = this.form.controls.usersBranch.value.findIndex(
            (item) => item.userId === id
          );

          this.form.controls.usersBranch.removeAt(index);
        }
      });
  }

  editDevice(index: number): void {
    this.form.controls.devices.controls.forEach((control, i) =>
      control.disable()
    );

    this.form.controls.devices.controls[index].enable();
  }

  addDevice(): void {
    if (this.newDevice.valid) {
      if (
        this.form.controls.devices
          .getRawValue()
          .findIndex((item) => item.name === this.newDevice.value) !== -1
      ) {
        this.alertService.danger('Já existe um dispositivo com esse nome.');

        return;
      }

      this.form.controls.devices.push(
        this.createDevice({ id: null, name: this.newDevice.value! })
      );

      this.newDevice.reset();
    } else {
      this.newDevice.markAllAsTouched();
    }
  }

  deleteDevice(index: number): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '500px',
      data: {
        title: 'Excluir dispositivo',
        description: 'Deseja realmente excluir esse dispositivo?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) this.form.controls.devices.removeAt(index);
      });
  }

  cancel(): void {
    const data: IBreadcrumb = this.breadcrumb[0];

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '500px',
      data: {
        title: data.textTitle,
        description: data.descriptionMsg,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) this.router.navigate([data.url]);
      });
  }

  updateUnit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();

      return;
    }

    this.loadingService.showLoading();
    this.unitsService
      .updateUnit(this.form.getRawValue() as IUnit)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res) => {
          if (res.success) {
            this.alertService.success('Unidade atualizada com sucesso.');
            this.router.navigate(['main/registration/units']);
          }
        },
      });
  }
}
