import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, inject, input, OnDestroy, output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { LoadingService } from './../../../../../../shared/services/loading.service';

import { MatInputModule } from '@angular/material/input';
import { FileService } from '../../../../../../shared/services/image-to-base64.service';
import { EWeights } from '../../../../../../utils/enums/weights.enum';
import { IQuestionForm } from '../../../../../../utils/interfaces/poll';

@Component({
  selector: 'app-selection',
  standalone: true,
  imports: [CommonModule, DragDropModule, ReactiveFormsModule, MatInputModule],
  providers: [FileService],
  templateUrl: './selection.component.html',
  styleUrl: './selection.component.scss',
})
export class SelectionComponent implements OnDestroy {
  private readonly fileService = inject(FileService);
  private readonly loadingService = inject(LoadingService);
  private readonly unsubscribe$ = new Subject();

  formQuestion = input.required<FormGroup<IQuestionForm>>();
  weights = EWeights;

  firstPage = input.required<boolean>();
  firstQuestion = input.required<boolean>();

  onCreatedAnswer = output();

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.formQuestion().controls.answers.controls,
      event.previousIndex,
      event.currentIndex
    );
    setTimeout(() => {
      this.formQuestion().controls.answers.controls.forEach(
        (control, index) => {
          control.controls.position.setValue(index + 1);
        }
      );
    }, 0);
  }

  removeAnswer(index: number): void {
    this.formQuestion().controls.answers.removeAt(index);

    this.changePosition();
  }

  changePosition(): void {
    this.formQuestion().controls.answers.controls.forEach((control, index) => {
      control.controls.position.setValue(index + 1);
    });
  }

  uploadImage(file: File, index: number): void {
    this.fileService
      .uploadImage(file)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.formQuestion().controls.answers.controls[
          index
        ].controls.img.setValue(res.data);
        this.loadingService.closeLoading();
      });
  }

  resizeImage(event: Event, index: number): void {
    const target = event.target as HTMLInputElement;
    const file = (target.files as FileList)[0];

    if (!file) return;

    this.loadingService.showLoading();

    this.fileService
      .resizeImage(file)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((newFile) => {
        this.uploadImage(newFile, index);
      });
  }
}
