import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { AlertService } from '../alert.service';

interface IToken {
  id: string;
  expiration: string;
  nbf: number;
  exp: number;
  iat: number;
}

export const checkPasswordChangeRequestGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const token = route.params['token'];
  let isValid = true;

  if (!token) isValid = false;
  else {
    const decodeToken: IToken = jwtDecode(token);
    if (new Date(decodeToken.exp * 1000) < new Date()) isValid = false;
  }

  if (!isValid) {
    inject(Router).navigate(['auth']);
    inject(AlertService).danger('O link para troca de senha expirou.');
  }

  return isValid;
};
