import { Injectable, signal } from '@angular/core';
import { IReportFilter } from '../../utils/interfaces/report';

@Injectable({
  providedIn: 'root',
})
export class ReportFilterService {
  protected filter = signal<IReportFilter | null>(null);

  getFilter(): IReportFilter | null {
    return this.filter();
  }

  setFilter(data: IReportFilter | null): void {
    this.filter.set(data);
  }

  clearFilter(filter: IReportFilter): void {
    this.filter.set(filter);
  }
}
