<div class="dates">
  <div class="date">
    <mat-form-field appearance="outline">
      <mat-label>Data inicial</mat-label>
      <input
        matInput
        type="date"
        [(ngModel)]="selected"
        (ngModelChange)="formatData()"
      />
      <mat-icon matSuffix>event</mat-icon>
      <mat-hint>DD/MM/YYYY</mat-hint>
    </mat-form-field>
    <mat-calendar
      #calendarStartDate
      [startAt]="startDate()"
      [(selected)]="startDate"
      (selectedChange)="selectDate()"
    ></mat-calendar>
  </div>
  <div class="date">
    <mat-form-field appearance="outline">
      <mat-label>Data final</mat-label>
      <input
        matInput
        type="date"
        [(ngModel)]="selected2"
        (ngModelChange)="formatData()"
      />
      <mat-icon matSuffix>event</mat-icon>
      <mat-hint>DD/MM/YYYY</mat-hint>
    </mat-form-field>
    <mat-calendar
      #calendarEndDate
      [startAt]="endDate()"
      [(selected)]="endDate"
      (selectedChange)="selectDate()"
    ></mat-calendar>
  </div>
</div>
