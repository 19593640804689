import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { configQuery } from '../../utils/config-query';
import { IResponse } from '../../utils/interfaces/response';
import { IUser } from '../../utils/interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly URI_API = environment.apiConfig.uri + 'User';

  constructor(private readonly httpClient: HttpClient) {}

  forgotPassword(email: string): Observable<IResponse<void>> {
    return this.httpClient.get<IResponse<void>>(
      `${this.URI_API}/forgotpassword/${email}`
    );
  }

  resetpassword(data: {
    confirmPassword: string;
    password: string;
    userId: string;
  }): Observable<IResponse<void>> {
    return this.httpClient.put<IResponse<void>>(
      `${this.URI_API}/resetpassword`,
      data
    );
  }

  getUsers(
    search: boolean,
    searchString: string = '',
    page: number = 0,
    take: number = 0,
    status: boolean | null = null
  ): Observable<IResponse<IUser[]>> {
    return this.httpClient.get<IResponse<IUser[]>>(
      `${this.URI_API}/all${configQuery(
        search,
        searchString,
        page,
        take,
        status
      )}`
    );
  }

  getUserById(id: string): Observable<IResponse<IUser>> {
    return this.httpClient.get<IResponse<IUser>>(`${this.URI_API}/${id}`);
  }

  createUser(data: IUser): Observable<IResponse<boolean>> {
    return this.httpClient.post<IResponse<boolean>>(
      `${this.URI_API}/create`,
      data
    );
  }

  updateUser(data: IUser): Observable<IResponse<boolean>> {
    return this.httpClient.put<IResponse<boolean>>(
      `${this.URI_API}/update`,
      data
    );
  }

  updateStatus(userID: string): Observable<IResponse<boolean>> {
    return this.httpClient.put<IResponse<boolean>>(
      `${this.URI_API}/updateStatus/${userID}`,
      {}
    );
  }
}
