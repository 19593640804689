<div class="card border-top card-title">
  <mat-form-field>
    <mat-label>Título da pesquisa</mat-label>
    <input
      matInput
      placeholder="Ex. Satisfação"
      maxlength="128"
      [formControl]="form().controls.title"
    />
    @if (form().controls.title.hasError('required')) {
    <mat-error>Título da pesquisa é obrigatório</mat-error>
    }
  </mat-form-field>

  <mat-form-field class="mat-select" appearance="outline">
    <mat-label>Empresa</mat-label>
    <mat-select [formControl]="form().controls.company">
      <mat-option [value]="null">Selecione</mat-option>
      <mat-option [value]="0">Sapore</mat-option>
      <mat-option [value]="1">UniFacilities</mat-option>
    </mat-select>
    @if (form().controls.company.hasError('required')) {
    <mat-error>Empresa é obrigatório</mat-error>
    }
  </mat-form-field>
</div>

@for (page of form().controls.pages.controls; track
page.controls.pageNumber.value; let pageIndex = $index) {
<div class="card border-left">
  <p class="title">Página {{ page.controls.pageNumber.value }}</p>

  @for (question of page.controls.questions.controls; track
  question.controls.number.value; let questionIndex = $index; let firstQuestion
  = $first) {
  <div class="card-form">
    <app-type-of-poll
      [formQuestion]="question"
      [firstPage]="page.controls.pageNumber.value === 1 && questionIndex === 0"
      [firstQuestion]="firstQuestion"
      (onCreatedAnswer)="onCreatedAnswer.emit($event)"
    />
    @if(page.controls.pageNumber.value === 1 && questionIndex === 0){
    <div class="delete-question"></div>
    } @else {
    <div class="delete-question">
      <mat-slide-toggle [formControl]="question.controls.requiredAnswer"
        >Obrigatório</mat-slide-toggle
      >
      <img
        src="assets/img/delete.png"
        alt="delete"
        (click)="onDeleteQuestion.emit({ page, questionIndex })"
      />
    </div>
    }
  </div>
  } @if (page.controls.questions.length < 2) {
  <div class="btns">
    <button class="btn danger__no-bg" (click)="onAddQuestion.emit(page)">
      + Adicionar pergunta
    </button>
  </div>
  } @if (page.controls.pageNumber.value! > 1) {

  <div class="actions">
    <div class="actions-page">
      <div class="timer">
        <p>
          Tempo para resposta:
          <span>{{ page.controls.timer.value }} segundos</span>
        </p>
        <mat-slider [discrete]="true" [min]="0" [max]="60">
          <input matSliderThumb [formControl]="page.controls.timer" />
        </mat-slider>
      </div>
      <div class="toggle">
        <mat-slide-toggle [formControl]="page.controls.showTimer"
          >Mostrar timer na tela</mat-slide-toggle
        >
      </div>
    </div>
    <img
      class="delete-img"
      title="Excluir página"
      src="assets/img/delete.png"
      alt="delete"
      (click)="onDeletePage.emit(pageIndex)"
    />
  </div>
  }
</div>
}

<button class="btn danger add-page" (click)="onCreatedPage.emit()">
  + Adicionar página
</button>

<div class="card border-left">
  <p class="title">Página final</p>

  <div class="end-img">
    @if (form().controls.endPage.controls.img.value) {
    <img
      class="upload"
      [src]="form().controls.endPage.controls.img.value"
      alt="upload"
      (click)="uploadFileEndPage.click()"
    />
    <img
      class="remove-img"
      src="assets/img/delete.png"
      alt="Delete img"
      title="Excluir imagem"
      (click)="form().controls.endPage.controls.img.setValue('')"
    />
    } @else {
    <img
      class="upload"
      src="assets/img/upload.png"
      alt="upload"
      (click)="uploadFileEndPage.click()"
    />
    }

    <input
      type="file"
      #uploadFileEndPage
      hidden
      (change)="resizeImage($event)"
      accept="image/*"
    />
  </div>

  <div class="inputs-end-page">
    <mat-form-field>
      <mat-label>Título da página final</mat-label>
      <input
        matInput
        placeholder="Ex. Satisfação"
        maxlength="128"
        [formControl]="form().controls.endPage.controls.title"
      />
      @if (form().controls.endPage.controls.title.hasError('required')) {
      <mat-error>Título da página final é obrigatório</mat-error>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>Descrição da página final</mat-label>
      <input
        matInput
        placeholder="Ex. Satisfação"
        maxlength="128"
        [formControl]="form().controls.endPage.controls.description"
      />
      @if (form().controls.endPage.controls.description.hasError('required')) {
      <mat-error>Título da página final é obrigatório</mat-error>
      }
    </mat-form-field>

    <div class="actions-page">
      <div class="timer">
        <p>
          Tempo para resposta:
          <span
            >{{ form().controls.endPage.controls.timer.value }} segundos</span
          >
        </p>
        <mat-slider [discrete]="true" [min]="2" [max]="60">
          <input
            matSliderThumb
            [formControl]="form().controls.endPage.controls.timer"
          />
        </mat-slider>
      </div>
      <div class="toggle">
        <mat-slide-toggle
          [formControl]="form().controls.endPage.controls.showTimer"
          >Mostrar timer na tela</mat-slide-toggle
        >
      </div>
    </div>
  </div>
</div>
