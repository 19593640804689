export const SingleColor = '#00b894';
export enum EColorsWeight {
  '#00b894' = 4,
  '#81ecec' = 3,
  '#fab1a0' = 2,
  '#d63031' = 1,
}
export const AllColors: string[] = [
  '#16a085',
  '#f39c12',
  '#2c3e50',
  '#27ae60',
  '#8e44ad',
  '#e67e22',
  '#2980b9',
  '#1abc9c',
  '#34495e',
  '#2ecc71',
  '#9b59b6',
  '#3498db',
  '#f1c40f',
  '#d35400',
  '#FFC312',
  '#C4E538',
  '#12CBC4',
  '#FDA7DF',
  '#F79F1F',
  '#A3CB38',
  '#1289A7',
  '#D980FA',
  '#EE5A24',
  '#009432',
  '#0652DD',
  '#9980FA',
  '#6F1E51',
  '#5758BB',
  '#1B1464',
  '#006266',
  '#16a085',
  '#f39c12',
  '#2c3e50',
  '#27ae60',
  '#8e44ad',
  '#e67e22',
  '#2980b9',
  '#1abc9c',
  '#34495e',
  '#2ecc71',
  '#9b59b6',
  '#3498db',
  '#f1c40f',
  '#d35400',
  '#FFC312',
  '#C4E538',
  '#12CBC4',
  '#FDA7DF',
  '#F79F1F',
  '#A3CB38',
  '#1289A7',
  '#D980FA',
  '#EE5A24',
  '#009432',
  '#0652DD',
  '#9980FA',
  '#6F1E51',
  '#5758BB',
  '#1B1464',
  '#006266',
];
