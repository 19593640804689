<h2>Selecione a pesquisa</h2>
<div class="card">
  <div class="card-header">
    <div class="inputs">
      <div class="input-icon">
        <input
          type="text"
          class="form-control"
          placeholder="Buscar"
          [formControl]="searchString"
        />
        <img src="assets/svg/search.svg" alt="search" />
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select [formControl]="searchStatus">
          <mat-option [value]="null">Todos</mat-option>
          <mat-option [value]="true">Ativos</mat-option>
          <mat-option [value]="false">Desativados</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <app-table
    [tableHeader]="tableHeader"
    [list]="list"
    [actionList]="actionList"
    (onSelectAction)="selectAction($event)"
    imgForActions="assets/img/view.png"
    [withStatusDescription]="true"
  />

  @if (countPoll) {
  <app-pagination
    [total]="countPoll"
    [value]="pagination"
    [showRowsSelect]="true"
    (onPaginationChange)="onPaginationChange($event)"
  />

  }
</div>
