<h2>Unidades</h2>
<div class="card">
  <div class="input-icon">
    <input
      type="text"
      class="form-control"
      placeholder="Buscar"
      [formControl]="searchText"
    />
    <img src="assets/svg/search.svg" alt="search" />
  </div>

  <app-table
    [tableHeader]="tableHeader"
    [list]="list"
    [actionList]="actionList"
    (onSelectAction)="selectedAction($event)"
    imgForActions="assets/img/edit.png"
  />

  <app-pagination
    [total]="listCount"
    [value]="pagination"
    [showRowsSelect]="true"
    (onPaginationChange)="onPaginationChange($event)"
  />
</div>
