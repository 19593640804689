import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { configQuery } from '../../utils/config-query';
import { ICustomer } from '../../utils/interfaces/customer';
import { IResponse } from '../../utils/interfaces/response';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  private readonly URI_API = environment.apiConfig.uri + 'Customer';

  constructor(private readonly httpClient: HttpClient) {}

  getCustomers(
    search: boolean,
    searchString: string = '',
    page: number = 0,
    take: number = 0,
    status: boolean | null = null
  ): Observable<IResponse<ICustomer[]>> {
    return this.httpClient.get<IResponse<ICustomer[]>>(
      `${this.URI_API}/filter${configQuery(search, searchString, page, take, status)}`
    );
  }
}
