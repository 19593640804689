import { CommonModule } from '@angular/common';
import { Component, effect } from '@angular/core';
import { Alert, AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-alert',
  standalone: true,
  template: `
    <div class="alert">
      @for (alert of alerts; track $index) {
      <div class="content">
        <div class="message" [ngClass]="alert.alertType">
          @if (alert.title) {
          <p>{{ alert.title }}</p>
          }
          {{ alert.message }}
        </div>
      </div>
      }
    </div>
  `,
  styles: [
    `
      .alert {
        position: fixed;
        top: 6rem;
        right: 1rem;
        z-index: 999999;
        max-width: 40rem;

        .content {
          .message {
            padding: 1rem;
            font-weight: 500;
            color: #fff;
            border-radius: 0.3125rem;

            p {
              font-size: 1.25rem;
            }

            &.success {
              background: #4caf50;
            }

            &.info {
              background: #2196f3;
            }

            &.warning {
              background: #ffeb3b;
              color: #000000;
            }

            &.danger {
              background: #ff5722;
            }
          }
        }

        .content:not(:first-child) {
          margin-top: 1rem;
        }
      }
    `,
  ],
  imports: [CommonModule],
  providers: [],
})
export class AlertComponent {
  timeout: number = 6000;
  alerts: Alert[] = [];

  constructor(private readonly alertService: AlertService) {
    effect(() => this.activateAlert(this.alertService.getAlert()));
  }

  activateAlert(alert: Alert | null): void {
    if (alert) {
      this.alerts.unshift(alert);

      setTimeout(() => {
        this.alerts = this.alerts.filter((a) => a.id !== alert?.id);
      }, this.timeout);
    }
  }
}
