import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from '../alert.service';
import { LoadingService } from '../loading.service';

interface BackendError {
  title: string;
  status: number;
  detail: string;
  instance: string;
  traceId: string;
  innerException: string;
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private readonly injector: Injector) {}

  handleError(error: any): void {
    const alertService = this.injector.get(AlertService);
    const loadingService = this.injector.get(LoadingService);

    if (error instanceof HttpErrorResponse) {
      const backendError: BackendError = error.error;

      if (backendError && 'detail' in backendError) {
        alertService.danger(
          `
          Error: ${backendError.detail || 'Ocorreu um erro inesperado.'}`,
          'Back-end'
        );
      } else {
        alertService.danger(
          `Error: ${error.message || 'Ocorreu um erro inesperado.'}`
        );
      }
    } else if (error.message.includes('user_cancelled')) {
      alertService.danger('O usuário cancelou o fluxo de autenticação!');
    } else {
      alertService.danger(
        `Error: ${error.message || 'Ocorreu um erro inesperado.'}`,
        'Front-end'
      );
    }

    console.error(error);
    setTimeout(() => loadingService.closeLoading(), 1000);
  }
}
