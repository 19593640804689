import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { configQuery } from '../../utils/config-query';
import { IResponse } from '../../utils/interfaces/response';
import { IUnit, IUnitList } from '../../utils/interfaces/unit';

@Injectable({
  providedIn: 'root',
})
export class UnitsService {
  private readonly URI_API = environment.apiConfig.uri + 'Branch';

  constructor(private readonly httpClient: HttpClient) {}

  getUnitsFilter(
    search: boolean,
    searchString: string = '',
    page: number = 0,
    take: number = 0,
    status: boolean | null = null
  ): Observable<IResponse<IUnitList[]>> {
    return this.httpClient.get<IResponse<IUnitList[]>>(
      `${this.URI_API}/filter${configQuery(
        search,
        searchString,
        page,
        take,
        status
      )}`
    );
  }

  getUnitById(id: string): Observable<IResponse<IUnit>> {
    return this.httpClient.get<IResponse<IUnit>>(`${this.URI_API}/${id}`);
  }

  getUnitUserPollById(id: string): Observable<IResponse<IUnit>> {
    return this.httpClient.get<IResponse<IUnit>>(
      `${this.URI_API}/user-poll/?pollId=${id}`
    );
  }

  updateUnit(unit: IUnit): Observable<IResponse<IUnit>> {
    return this.httpClient.put<IResponse<IUnit>>(
      `${this.URI_API}/update`,
      unit
    );
  }
}
