@for (data of data(); track $index) {
<div class="content-bar">
  <div class="img-description">
    @if (data.img) {
    <img [src]="data.img" alt="img" />
    }

    <p>{{ data.description }}</p>
  </div>
  <div class="bar">
    <div
      class="bar-colors"
      [style.background]="
        dataType() === chartColorsEnum.singleColor
          ? singleColor
          : dataType() === chartColorsEnum.colorsWeight
          ? colorsWeight[data.weight || 4]
          : allColors[$index]
      "
      [style.width.%]="data.porcentage"
    ></div>
  </div>
  <div class="values">
    <p>{{ data.count }} | {{ data.porcentage }}%</p>
  </div>
</div>
}
