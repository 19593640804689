import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';

import { IResponse } from '../../../utils/interfaces/response';
import { IUser } from '../../../utils/interfaces/user';
import { UserService } from '../user.service';

export const getUserResolver: ResolveFn<Observable<IResponse<IUser>>> = (
  route,
  state
) => {
  const userService = inject(UserService);
  return userService.getUserById(route.params['id']);
};

export const getUsersResolver: ResolveFn<Observable<IResponse<IUser[]>>> = (
  route,
  state
) => {
  const userService = inject(UserService);
  return userService.getUsers(false, '', 1, 10);
};
