import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

export interface IPaginationValue {
  page: number;
  take: number;
}

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() value: IPaginationValue = { page: 1, take: 10 };
  @Input() total = 10;
  @Input() visibleRangeLength = 5;
  @Input() pageSizes: number[] = [5, 10, 25, 50];
  @Input() showRowsSelect: boolean = false;
  @Input() rowsPerPage: number[] = [10, 20, 50, 100];

  @Output() onPaginationChange: EventEmitter<IPaginationValue> =
    new EventEmitter();

  public totalPages: number = 15;
  public visiblePages: number[] = [];

  ngOnInit(): void {
    this.updateTotalPages();
    this.updateVisiblePages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['total'] || changes['value']) {
      this.updateTotalPages();
      this.updateVisiblePages();
    }
  }

  public selectPage(page: number): void {
    this.value = { ...this.value, page };
    this.updateVisiblePages();

    this.onPaginationChange.emit(this.value);
  }

  public selectPageSize(): void {
    this.value = { page: 1, take: +this.value.take };

    this.updateTotalPages();
    this.updateVisiblePages();

    this.onPaginationChange.emit(this.value);
  }

  private updateVisiblePages(): void {
    const length = Math.min(this.totalPages, this.visibleRangeLength);

    const startIndex = Math.max(
      Math.min(
        this.value.page - Math.ceil(length / 2),
        this.totalPages - length
      ),
      0
    );

    this.visiblePages = Array.from(
      new Array(length).keys(),
      (item) => item + startIndex + 1
    );
  }

  private updateTotalPages(): void {
    this.totalPages = Math.ceil(this.total / this.value.take);
  }
}
