import { Component, inject, OnDestroy, OnInit } from '@angular/core';

import { CommonModule } from '@angular/common';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { ConfirmationModalComponent } from '../../../shared/components/confirmation-modal/confirmation-modal.component';
import { AlertService } from '../../../shared/services/alert.service';
import { LoadingService } from '../../../shared/services/loading.service';
import { PollService } from '../../../shared/services/poll.service';
import { CompanyEnum } from '../../../utils/enums/company.enum';
import {
  IAnswer,
  IAnswerForm,
  IEndPageForm,
  IPage,
  IPageForm,
  IPoll,
  IPollForm,
  IQuestion,
  IQuestionForm,
} from '../../../utils/interfaces/poll';
import { PreviewPollComponent } from '../preview-poll/preview-poll.component';
import { PageAssignmentComponent } from './page-assignment/page-assignment.component';
import { PollConfigurationComponent } from './poll-configuration/poll-configuration.component';
import { QuantityAnswersComponent } from './quantity-answers/quantity-answers.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    QuantityAnswersComponent,
    PollConfigurationComponent,
  ],
  templateUrl: './poll.component.html',
  styleUrl: './poll.component.scss',
})
export class PollComponent implements OnInit, OnDestroy {
  private readonly dialog = inject(MatDialog);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly pollService = inject(PollService);
  private readonly router = inject(Router);

  private readonly loadingService = inject(LoadingService);
  private readonly alertService = inject(AlertService);
  private readonly unsubscribe$ = new Subject();

  step = 0;
  form!: FormGroup<IPollForm>;

  poll!: IPoll;

  ngOnInit(): void {
    this.poll =
      (this.activatedRoute.snapshot.data['poll']?.data as IPoll) ?? null;

    this.form = new FormGroup<IPollForm>({
      numberResponses: new FormControl<number>(
        this.poll?.numberResponses ?? 0,
        Validators.required
      ),
      title: new FormControl<string>(
        this.poll?.title ?? '',
        Validators.required
      ),
      company: new FormControl<CompanyEnum>(
        this.poll?.company ?? null,
        Validators.required
      ),
      pages: new FormArray<FormGroup<IPageForm>>([]),
      endPage: new FormGroup<IEndPageForm>({
        id: new FormControl<string | null>(this.poll?.endPage.id ?? null),
        title: new FormControl<string>(
          this.poll?.endPage.title ?? 'Obrigado pela sua contribuição!',
          Validators.required
        ),
        description: new FormControl<string>(
          this.poll?.endPage.description ??
            'Seu comentário vai ajudar a gente a evoluir.',
          Validators.required
        ),
        img: new FormControl<string>(this.poll?.endPage.img ?? ''),
        timer: new FormControl<number>(
          this.poll?.endPage.timer ?? 3,
          Validators.required
        ),
        showTimer: new FormControl<boolean>(
          this.poll?.endPage.showTimer ?? false
        ),
      }),
    });

    if (this.poll) {
      this.step = 1;
      this.form.addControl('id', new FormControl<string>(this.poll.id));
      this.handlerData();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  handlerData(): void {
    this.poll.pages.forEach((pag) => {
      const page = this.createPage(pag);

      pag.questions.forEach((question) => {
        const quest = this.createQuestion(question);

        question.answers.forEach((answer) => {
          const answ = this.createAnswer(question.type !== 3, answer);

          quest.controls.answers.push(answ);
        });

        page.controls.questions.push(quest);
      });

      this.form.controls.pages.push(page);
    });
  }

  handlerPage() {
    const page = this.createPage();

    page.controls.pageNumber.setValue(this.form.controls.pages.length + 1);

    this.handlerQuestion(page, !this.form.controls.pages.length);

    this.form.controls.pages.push(page);

    this.step = 1;
  }

  createPage(pag: IPage | null = null): FormGroup<IPageForm> {
    const item = new FormGroup<IPageForm>({
      pageNumber: new FormControl<number>(
        pag?.pageNumber ?? 0,
        Validators.required
      ),
      questions: new FormArray<FormGroup<IQuestionForm>>([]),
      timer: new FormControl<number>(pag?.timer ?? 5, Validators.required),
      showTimer: new FormControl<boolean>(pag?.showTimer ?? false),
    });

    if (pag?.id) {
      item.addControl('id', new FormControl<string>(pag.id));
    }

    return item;
  }

  handlerQuestion(
    page: FormGroup<IPageForm>,
    hasWeight = false
  ): FormGroup<IPageForm> {
    const question = this.createQuestion();

    question.controls.number.setValue(page.controls.questions.length + 1);

    let numberResponses: number[] = [1, 2];

    if (page.controls.pageNumber.value === 1) {
      if (!page.controls.questions.length) {
        const quantityAnswers = this.form.controls.numberResponses.value;

        if (quantityAnswers === 4) {
          numberResponses = [1, 2, 3, 4];
        }
        if (quantityAnswers === 3) {
          numberResponses = [1, 3, 4];
        }
        if (quantityAnswers === 2) {
          numberResponses = [1, 4];
        }
        question.controls.requiredAnswer.setValue(true);
      }
    }

    for (const element of numberResponses) {
      this.handlerAnswer({
        question,
        weight: hasWeight ? (element as 1 | 2 | 3 | 4) : null,
        required: true,
      });
    }

    page.controls.questions.push(question);

    return page;
  }

  createQuestion(question: IQuestion | null = null): FormGroup<IQuestionForm> {
    const item = new FormGroup<IQuestionForm>({
      number: new FormControl<number>(question?.number ?? 0),
      title: new FormControl<string | null>(
        question?.title ?? null,
        Validators.required
      ),
      description: new FormControl<string | null>(
        question?.description ?? null,
        Validators.required
      ),
      answers: new FormArray<FormGroup<IAnswerForm>>([]),
      type: new FormControl<number | null>(
        question?.type ?? 1,
        Validators.required
      ),
      requiredAnswer: new FormControl<boolean>(
        question?.requiredAnswer ?? false
      ),
    });

    if (question?.id) {
      item.addControl('id', new FormControl<string>(question.id));
    }

    return item;
  }

  deleteQuestion(data: { page: FormGroup<IPageForm>; questionIndex: number }) {
    data.page.controls.questions.removeAt(data.questionIndex);
  }

  handlerAnswer(data: {
    question: FormGroup<IQuestionForm>;
    weight: 1 | 2 | 3 | 4 | null;
    required: boolean;
  }): FormGroup<IQuestionForm> {
    const answer = this.createAnswer(data.required);

    answer.controls.weight.setValue(data.weight);
    answer.controls.position.setValue(
      data.question.controls.answers.length + 1
    );

    data.question.controls.answers?.push(answer);

    return data.question;
  }

  createAnswer(
    required: boolean,
    answer: IAnswer | null = null
  ): FormGroup<IAnswerForm> {
    const item = new FormGroup<IAnswerForm>({
      position: new FormControl<number>(answer?.position ?? 0),
      weight: new FormControl<1 | 2 | 3 | 4 | null>(answer?.weight ?? null),
      img: new FormControl<string | null>(answer?.img ?? ''),
      description: new FormControl<string | null>(answer?.description ?? ''),
      next: new FormControl<number | null>(answer?.next ?? null),
    });

    if (answer?.id) {
      item.addControl('id', new FormControl<string>(answer.id));
    }

    if (required) {
      item.controls.description.setValidators(Validators.required);
    }

    return item;
  }

  pageAssignmentDialog() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const dialogRef = this.dialog.open(PageAssignmentComponent, {
      minWidth: 900,
      data: {
        form: this.form,
        formValue: this.form.getRawValue(),
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) {
          this.form.setValue(result);

          this.handlerSavePoll(this.form.getRawValue() as IPoll);
        }
      });
  }

  handlerSavePoll(poll: IPoll): void {
    if (poll.id) {
      this.updatePoll(poll);
    } else {
      this.createPoll(poll);
    }
  }

  createPoll(poll: IPoll): void {
    this.loadingService.showLoading();

    this.pollService
      .createPoll(poll)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.loadingService.closeLoading();

        this.alertService.success('Pesquisa criada com sucesso');

        this.router.navigate(['main/polls/list']);
      });
  }

  updatePoll(poll: IPoll): void {
    this.loadingService.showLoading();

    this.pollService
      .updatePoll(poll)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.loadingService.closeLoading();

        this.alertService.success('Pesquisa atualizada com sucesso');

        this.router.navigate(['main/polls/list']);
      });
  }

  preview() {
    this.dialog.open(PreviewPollComponent, {
      minWidth: 800,
      maxWidth: 800,
      data: {
        formValue: this.form.getRawValue(),
      },
    });
  }

  exitPage() {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      minWidth: 400,
      data: {
        title: 'Deseja sair?',
        description: 'Você perderá todas as informações não salvas.',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result) => {
        if (result) this.router.navigate(['main/polls/list']);
      });
  }

  deletePage(index: number) {
    this.form.controls.pages.removeAt(index);
  }
}
