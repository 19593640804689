@for (page of poll().pages; track $index) {
<p class="page-number">Página {{ page.pageNumber }}</p>

<div class="questions">
  @for (question of page.questions; track $index) {
  <div class="question">
    <p class="question-title">{{ question.number }}. {{ question.title }}</p>

    @for (answer of question.answers; track $index) {
    <div class="answers">
      <p>{{ answer.description }}</p>
      @if (question.type !== 3) {
      <mat-checkbox></mat-checkbox>
      }
    </div>

    }
  </div>
  }
</div>
}
