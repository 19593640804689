<div class="content">
  <div class="container">
    <p class="title">Crie uma senha nova</p>
    <p class="description">
      Para proteger sua conta, escolha uma senha forte que você não usou antes e
      que tenha pelo menos 8 caracteres, letra maiúscula, minúscula e caractere
      especial.
    </p>
    <form [formGroup]="form">
      <mat-form-field appearance="outline">
        <mat-label>Nova senha</mat-label>
        <input
          matInput
          [type]="hide ? 'password' : 'text'"
          placeholder="Ex. Teste123Ab#"
          formControlName="password"
        />
        <mat-icon matSuffix (click)="hide = !hide">{{
          hide ? "visibility_off" : "visibility"
        }}</mat-icon>
        @if (form.controls['password'].hasError('required')) {
        <mat-error>Nova senha é obrigatório</mat-error>
        }
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Repita a senha</mat-label>
        <input
          matInput
          [type]="hide2 ? 'password' : 'text'"
          placeholder="Ex. Teste123Ab#"
          formControlName="confirmPassword"
        />
        <mat-icon matSuffix (click)="hide2 = !hide2">{{
          hide2 ? "visibility_off" : "visibility"
        }}</mat-icon>
        @if (form.controls['confirmPassword'].hasError('required')) {
        <mat-error>Repetir a senha é obrigatório</mat-error>
        } @if (form.controls['confirmPassword'].hasError('passwordsMismatch')) {
        <mat-error>As senhas não conferem</mat-error>
        }
      </mat-form-field>
    </form>

    <button
      class="btn danger"
      [disabled]="inputError || form.invalid"
      (click)="changePassword()"
    >
      Enviar
    </button>

    <p class="title-alerts">Sua senha deve conter:</p>

    <p class="alerts" [ngClass]="{ success: !errors.minCharacters }">
      @if (errors.minCharacters) {
      <img src="assets/img/info.png" alt="info" />
      } @else {
      <img src="assets/img/check.png" alt="Check" />
      } Mínimo de 8 caracteres
    </p>
    <p class="alerts" [ngClass]="{ success: !errors.uppercase }">
      @if (errors.uppercase) {
      <img src="assets/img/info.png" alt="info" />
      } @else {
      <img src="assets/img/check.png" alt="Check" />
      }Pelo menos uma letra maiúscula
    </p>
    <p class="alerts" [ngClass]="{ success: !errors.lowercase }">
      @if (errors.lowercase) {
      <img src="assets/img/info.png" alt="info" />
      } @else {
      <img src="assets/img/check.png" alt="Check" />
      }Pelo menos uma letra minúscula
    </p>
    <p class="alerts" [ngClass]="{ success: !errors.number }">
      @if (errors.number) {
      <img src="assets/img/info.png" alt="info" />
      } @else {
      <img src="assets/img/check.png" alt="Check" />
      }Pelo menos um número
    </p>
    <p class="alerts" [ngClass]="{ success: !errors.specialCharacter }">
      @if (errors.specialCharacter) {
      <img src="assets/img/info.png" alt="info" />
      } @else {
      <img src="assets/img/check.png" alt="Check" />
      }Pelo menos um símbolo
      {{ "(EX: *#@!$&)" }}
    </p>
  </div>
</div>
