<div class="header">
  <img class="logo" src="assets/svg/logo.svg" alt="logo" />

  <div class="menu">
    <p routerLink="./home" routerLinkActive="active">Home</p>
    <p routerLink="./registration" routerLinkActive="active">Cadastro</p>
    <p routerLink="./polls" routerLinkActive="active">Pesquisas</p>
    <p routerLink="./report" routerLinkActive="active">Relatório</p>
  </div>

  <div class="name-logout">
    <div class="profile-img">
      @if (photo) {
      <img
        class="profile-img"
        [src]="
          photo.includes('base64') ? 'data:image/png;base64,' + photo : photo
        "
        alt="profile"
      />
      } @else {
      {{ shortName }}
      }
    </div>
    <div>
      <p class="name">{{ unique_name }}</p>
      <p class="profile">{{ role }}</p>
    </div>
    <img
      class="logout"
      src="assets/svg/SignOut.svg"
      alt="logout"
      (click)="logout()"
    />
  </div>
</div>
