import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { AlertService } from './../../../shared/services/alert.service';
import { UserService } from './../../../shared/services/user.service';

import { debounceTime, Subject, takeUntil } from 'rxjs';

import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import {
  IPaginationValue,
  PaginationComponent,
} from '../../../shared/components/pagination/pagination.component';
import { TableComponent } from '../../../shared/components/table/table.component';
import { LoadingService } from '../../../shared/services/loading.service';
import { ITableHeader } from '../../../utils/interfaces/table-header';
import { IUser } from '../../../utils/interfaces/user';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TableComponent,
    PaginationComponent,
    MatSelectModule,
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss',
})
export class UsersComponent implements OnInit, OnDestroy {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly userService = inject(UserService);
  private readonly loadingService = inject(LoadingService);
  private readonly alertService = inject(AlertService);
  private readonly unsubscribe$ = new Subject();

  searchUser = new FormControl('');
  userInactive = new FormControl<boolean | null>(null);

  tableHeader: ITableHeader[] = [
    {
      description: 'Nome',
      propertyKey: 'name',
      status: false,
      date: false,
      actions: false,
      filter: false,
    },
    {
      description: 'E-mail',
      propertyKey: 'email',
      status: false,
      date: false,
      actions: false,
      filter: false,
    },
    {
      description: 'Perfil',
      propertyKey: 'role.name',
      status: false,
      date: false,
      actions: false,
      filter: false,
    },
    {
      description: 'Status',
      propertyKey: 'active',
      status: true,
      date: false,
      actions: false,
      filter: false,
    },
    {
      description: 'Ações',
      propertyKey: '',
      status: false,
      date: false,
      actions: true,
      filter: false,
    },
  ];

  actionList: string[] = ['Editar'];

  list: IUser[] = [];
  countUsers = 0;

  pagination: IPaginationValue = {
    page: 1,
    take: 10,
  };

  ngOnInit(): void {
    const { count, data } = this.activatedRoute.snapshot.data['users'];
    this.list = data;
    this.countUsers = count ?? 0;

    this.searchUser.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(debounceTime(700))
      .subscribe(() => this.getUsers(true));

    this.userInactive.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.getUsers(true));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  changeStatus(userID: string): void {
    this.loadingService.showLoading();

    this.userService
      .updateStatus(userID)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.loadingService.closeLoading();
        this.alertService.success('Usuário atualizado com sucesso');
      });
  }

  selectAction(event: { id: string; func: string }): void {
    if (event.func === 'Editar') {
      this.router.navigate(['./main/registration/user', event.id]);
    }
  }

  onPaginationChange(event: IPaginationValue): void {
    this.pagination = event;

    this.getUsers();
  }

  async getUsers(search = false): Promise<void> {
    this.loadingService.showLoading();

    this.userService
      .getUsers(
        search,
        this.searchUser.value ?? '',
        this.pagination.page,
        this.pagination.take,
        this.userInactive.value
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.list = res.data;
        this.countUsers = res.count ?? 0;
        if (search) {
          this.pagination.page = 1;
        }

        this.loadingService.closeLoading();
      });
  }
}
