<div class="card">
  <p class="title">Qual modelo de pesquisa você quer criar?</p>

  <div class="cards-emojis">
    <div
      class="answers"
      [ngClass]="{ active: count().value === 4 }"
      (click)="count().setValue(4)"
    >
      <p class="title-card">Pesquisa com 4 respostas</p>
      <div class="itens">
        <div class="emojis">
          <img src="assets/img/emoji-1.png" alt="emoji insatisfeito" />
          <p>Insatisfeito</p>
        </div>
        <div class="emojis">
          <img
            src="assets/img/emoji-2.png"
            alt="emoji parcialmente insatisfeito"
          />
          <p>Parcialmente insatisfeito</p>
        </div>
        <div class="emojis">
          <img src="assets/img/emoji-3.png" alt="emoji satisfeito" />
          <p>Satisfeito</p>
        </div>
        <div class="emojis">
          <img src="assets/img/emoji-4.png" alt="emoji muito satisfeito" />
          <p>Muito satisfeito</p>
        </div>
      </div>
    </div>
    <div
      class="answers"
      [ngClass]="{ active: count().value === 3 }"
      (click)="count().setValue(3)"
    >
      <p class="title-card">Pesquisa com 3 respostas</p>
      <div class="itens">
        <div class="emojis">
          <img src="assets/img/emoji-1.png" alt="emoji insatisfeito" />
          <p>Insatisfeito</p>
        </div>
        <div class="emojis">
          <img
            src="assets/img/emoji-2.png"
            alt="emoji parcialmente insatisfeito"
          />
          <p>Parcialmente insatisfeito</p>
        </div>
        <div class="emojis">
          <img src="assets/img/emoji-4.png" alt="emoji muito satisfeito" />
          <p>Muito satisfeito</p>
        </div>
      </div>
    </div>
    <div
      class="answers"
      [ngClass]="{ active: count().value === 2 }"
      (click)="count().setValue(2)"
    >
      <p class="title-card">Pesquisa com 2 respostas</p>
      <div class="itens">
        <div class="emojis">
          <img src="assets/img/emoji-1.png" alt="emoji insatisfeito" />
          <p>Insatisfeito</p>
        </div>
        <div class="emojis">
          <img src="assets/img/emoji-4.png" alt="emoji muito satisfeito" />
          <p>Muito satisfeito</p>
        </div>
      </div>
    </div>
  </div>
</div>
