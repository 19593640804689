<app-breadcrumb [breadcrumb]="breadcrumb" />
<h2>@if (!form.controls.id?.value) { Novo usuário } @else { Editar usuário}</h2>
<div class="card">
  <p class="photo">Foto do usuário</p>
  <div class="img-profile">
    @if (form.controls.photo.value) {
    <img
      class="img"
      [src]="form.controls.photo.value"
      alt="User"
      (click)="inputFile.click()"
    />
    } @else {
    <img
      class="img"
      src="assets/img/account_circle.png"
      alt="User"
      (click)="inputFile.click()"
    />
    }
    <input
      type="file"
      (change)="resizeImage($event)"
      #inputFile
      hidden
      accept="image/*"
    />

    <img
      class="delete-img"
      src="assets/img/delete.png"
      alt="delete"
      (click)="form.controls.photo.setValue(null); inputFile.value = ''"
    />
  </div>

  <form [formGroup]="form">
    <div class="input-forms">
      <mat-form-field>
        <mat-label>Nome</mat-label>
        <input matInput placeholder="Digite aqui..." formControlName="name" />
        @if (form.controls.name.hasError('required')) {
        <mat-error>Nome é obrigatório</mat-error>
        }
      </mat-form-field>

      <mat-form-field>
        <mat-label>E-mail</mat-label>
        <input matInput placeholder="Digite aqui..." formControlName="email" />
        @if (form.controls.email.hasError('required')) {
        <mat-error>Email é obrigatório</mat-error>
        } @if (form.controls.email.hasError('email')) {
        <mat-error>Por favor insira um endereço de e-mail válido</mat-error>
        }
      </mat-form-field>

      <mat-form-field>
        <mat-label>Confirmar e-mail</mat-label>
        <input
          matInput
          placeholder="Digite aqui..."
          formControlName="confirmEmail"
        />
        @if (form.controls.confirmEmail.hasError('required')) {
        <mat-error>Confirmação de email é obrigatório</mat-error>
        } @if (form.controls.confirmEmail.hasError('emailsMismatch')) {
        <mat-error>Os emails não são compatíveis</mat-error>
        }
      </mat-form-field>
    </div>

    <div class="input-forms">
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select formControlName="active">
          <mat-option [value]="true">Ativo</mat-option>
          <mat-option [value]="false">Desativado</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Perfil</mat-label>
        <mat-select formControlName="roleId">
          <mat-option value="0ce77a2a-70a4-477d-9720-aada64089436"
            >Admin</mat-option
          >

          <mat-option value="f420471b-4cc6-40f3-8743-d08aebf0854a"
            >Gerente de unidade</mat-option
          >
        </mat-select>
        @if (form.controls.roleId.hasError('required')) {
        <mat-error>Perfil é obrigatório</mat-error>
        }
      </mat-form-field>

      <mat-form-field>
        <mat-label>Frequência de e-mail</mat-label>
        <mat-select formControlName="emailFrequencyList" multiple>
          <mat-option value="0" (click)="selectFrequencyValue('0')"
            >Nenhum</mat-option
          >
          <mat-option value="1" (click)="selectFrequencyValue('1')"
            >Diário</mat-option
          >
          <mat-option value="2" (click)="selectFrequencyValue('2')"
            >Semanal</mat-option
          >
          <mat-option value="3" (click)="selectFrequencyValue('3')"
            >Mensal</mat-option
          >
        </mat-select>
        @if (form.controls.emailFrequencyList.hasError('required')) {
        <mat-error>Frequência de e-mail é obrigatório</mat-error>
        }
      </mat-form-field>
    </div>

    <mat-form-field class="example-full-width">
      <mat-label>CR / Unidade</mat-label>
      <input
        type="text"
        placeholder="Procurar CR / unidade..."
        aria-label="CR / Unidade"
        matInput
        [formControl]="unitSearch"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        @if (loadingUnits) {
        <mat-option disabled>Carregando...</mat-option>
        } @for (option of unitsList; track option.id) {
        <mat-option [value]="option.id" (click)="addUnit(option)">{{
          option.name
        }}</mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>

    @for (unit of this.form.controls['branchesUser'].value; track $index) {
    <div class="users-units">
      <mat-form-field>
        <mat-label>Unidade</mat-label>
        <input matInput [value]="unit.name" disabled />
      </mat-form-field>

      <div class="btn-add">
        <img
          src="assets/img/delete.png"
          alt="delete"
          (click)="deleteUnit(unit.branchId)"
        />
      </div>
    </div>
    } @empty {
    <div class="empty">
      <p>Não existe unidades adicionadas à este usuário</p>
    </div>
    }
  </form>

  <div class="update">
    <button class="btn danger__no-bg" (click)="cancel()">Cancelar</button>
    <button class="btn danger" (click)="handlerSaveUser()">
      @if (!form.controls.id?.value) { Cadastrar } @else { Atualizar }
    </button>
  </div>
</div>
