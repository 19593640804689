import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IReportFilter, IReportSummary } from '../../utils/interfaces/report';
import { IResponse } from '../../utils/interfaces/response';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private readonly URI_API = environment.apiConfig.uri + 'Report';

  constructor(private readonly httpClient: HttpClient) {}

  getReport(filter: IReportFilter): Observable<IResponse<IReportSummary>> {
    return this.httpClient.post<IResponse<IReportSummary>>(
      `${this.URI_API}/consolidated`,
      filter
    );
  }
}
