<div
  class="answers"
  cdkDropList
  cdkDropListOrientation="mixed"
  (cdkDropListDropped)="drop($event)"
>
  @for (answer of formQuestion().controls.answers.controls; track $index) {
  <div class="answer" cdkDrag>
    <div class="custom-placeholder" *cdkDragPlaceholder></div>
    <img class="dragDrop" src="assets/img/dragDrop.png" alt="upload" />
    <p>{{ weights[answer.controls.weight.value!] }}</p>

    @if (!answer.controls.img.value) {
    <img
      class="upload"
      src="assets/img/upload.png"
      alt="upload"
      (click)="uploadFile.click()"
    />
    } @else {
    <img
      class="upload"
      [src]="answer.controls.img.value"
      alt="upload"
      (click)="uploadFile.click()"
    />

    <img
      class="remove-img"
      src="assets/img/delete.png"
      alt="Delete img"
      title="Excluir imagem"
      (click)="answer.controls.img.setValue('')"
    />
    }

    <input
      type="file"
      #uploadFile
      hidden
      (change)="resizeImage($event, $index)"
      accept="image/*"
    />

    <mat-form-field>
      <mat-label>Conteúdo</mat-label>
      <input
        matInput
        placeholder="Ex. Relevância dos resultados"
        maxlength="128"
        [formControl]="answer.controls.description"
      />
      @if (answer.controls.description.hasError('required')) {
      <mat-error>Descrição da resposta é obrigatório</mat-error>
      }
    </mat-form-field>

    @if(firstPage() && firstQuestion()){
    <div class="delete-icon"></div>
    } @else if ( $index > 1){
    <div class="delete-icon">
      <img
        title="Excluir resposta"
        src="assets/img/delete.png"
        alt="Delete"
        (click)="removeAnswer($index)"
      />
    </div>
    }@else {
    <div class="delete-icon"></div>
    }
  </div>
  } @if(!firstPage() || !firstQuestion()){
  <img
    class="btn-add"
    src="assets/img/add-btn.png"
    alt="Add"
    (click)="onCreatedAnswer.emit()"
  />
  }
</div>
