import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UnitsService } from './../../../shared/services/units.service';

import { debounceTime, Subject, takeUntil } from 'rxjs';
import {
  IPaginationValue,
  PaginationComponent,
} from '../../../shared/components/pagination/pagination.component';
import { TableComponent } from '../../../shared/components/table/table.component';
import { LoadingService } from '../../../shared/services/loading.service';
import { ITableHeader } from '../../../utils/interfaces/table-header';
import { IUnitList } from '../../../utils/interfaces/unit';

@Component({
  selector: 'app-unit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TableComponent,
    PaginationComponent,
  ],
  templateUrl: './units.component.html',
  styleUrl: './units.component.scss',
})
export class UnitsComponent implements OnInit, OnDestroy {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly loadingService = inject(LoadingService);
  private readonly unitsService = inject(UnitsService);
  private readonly unsubscribe$ = new Subject();

  tableHeader: ITableHeader[] = [
    {
      description: 'CR',
      propertyKey: 'code',
      status: false,
      date: false,
      actions: false,
      filter: false,
    },
    {
      description: 'Unidade',
      propertyKey: 'name',
      status: false,
      date: false,
      actions: false,
      filter: false,
    },
    {
      description: 'Cliente',
      propertyKey: 'customerName',
      status: false,
      date: false,
      actions: false,
      filter: false,
    },
    {
      description: 'Ações',
      propertyKey: '',
      status: false,
      date: false,
      actions: true,
      filter: false,
    },
  ];

  actionList: string[] = ['Editar'];

  list: IUnitList[] = [];

  listCount = 0;

  pagination = {
    page: 1,
    take: 10,
  };

  searchText = new FormControl('');

  ngOnInit(): void {
    const { data, count } = this.activatedRoute.snapshot.data['units'];
    this.list = data;
    this.listCount = count;

    this.searchText.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(debounceTime(700))
      .subscribe(() => this.getUnits(true));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
  selectedAction(event: { id: string; func: string }): void {
    if (event.func === 'Editar') this.editUnit(event.id);
  }

  editUnit(id: string): void {
    this.router.navigate(['main/registration/unit', id]);
  }

  getUnits(search = false): void {
    this.loadingService.showLoading();
    this.unitsService
      .getUnitsFilter(
        search,
        this.searchText.value ?? '',
        this.pagination.page,
        this.pagination.take
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.list = res.data;
        this.listCount = res.count ?? 0;

        this.loadingService.closeLoading();

        if (search) {
          this.pagination.page = 1;
        }
      });
  }

  onPaginationChange(event: IPaginationValue): void {
    this.pagination = event;

    this.getUnits();
  }
}
