import { Routes } from '@angular/router';

import { getPollResolver } from '../../../shared/services/resolvers/get-poll.resolver';
import { getUnitUserPollResolver } from '../../../shared/services/resolvers/get-unit.resolver';
import { ReportDataComponent } from './report-data.component';
import { SummaryComponent } from './summary/summary.component';

export const reportDataRoutes: Routes = [
  {
    path: ':id',
    resolve: {
      poll: getPollResolver,
      unitUserPoll: getUnitUserPollResolver,
    },
    loadComponent: () => ReportDataComponent,
    children: [
      {
        path: '',
        redirectTo: 'summary',
        pathMatch: 'full',
      },
      {
        path: 'summary',
        loadComponent: () => SummaryComponent,
      },
    ],
  },
];
