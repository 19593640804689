<div class="pagination">
  @if (showRowsSelect) {
  <div class="limit-page">
    <label for="selectRows">Linhas por página:</label>
    <select
      id="selectRows"
      [(ngModel)]="value.take"
      (ngModelChange)="selectPageSize()"
    >
      @for (item of rowsPerPage; track $index) {
      <option [value]="item">
        {{ item }}
      </option>
      }
    </select>
  </div>
  }

  <button [disabled]="value.page === 1" (click)="selectPage(value.page - 1)">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 14.0964L11.0843 16L3 8L11.0843 2.2845e-08L13 1.90364L6.83943 8L13 14.0964Z"
        fill="#2D2E33"
      />
    </svg>
  </button>
  @for (page of visiblePages; track $index) {
  <button
    [ngClass]="{ selected: value.page === page }"
    (click)="selectPage(page)"
  >
    {{ page }}
  </button>
  }
  <button
    [disabled]="value.page === totalPages"
    (click)="selectPage(value.page + 1)"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 5.90364L8.91574 4L17 12L8.91574 20L7 18.0964L13.1606 12L7 5.90364Z"
        fill="#2D2E33"
      />
    </svg>
  </button>
</div>
