import { Routes } from '@angular/router';

import { authRoutes } from './auth/auth.routes';
import { mainRoutes } from './main/main.routes';
import { accountGuard } from './shared/services/guards/account.guard';
import { hasProfileandBranchGuard } from './shared/services/guards/has-profile-branch.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth',
  },
  {
    path: 'auth',
    loadChildren: () => authRoutes,
  },
  {
    path: 'main',
    canActivateChild: [accountGuard, hasProfileandBranchGuard],
    loadChildren: () => mainRoutes,
  },
];
