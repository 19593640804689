import { CommonModule } from '@angular/common';
import { Component, effect, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { CustomBarChartComponent } from '../../../../shared/components/custom-bar-chart/custom-bar-chart.component';
import { ReportFilterService } from '../../../../shared/services/report-filter.service';
import { ReportService } from '../../../../shared/services/report.service';
import { ChartTypeColorsEnum } from '../../../../utils/enums/chart-colors.enum';
import {
  IReportFilter,
  IReportSummary,
} from '../../../../utils/interfaces/report';
import { LoadingService } from './../../../../shared/services/loading.service';

@Component({
  selector: 'app-summary',
  standalone: true,
  imports: [CommonModule, CustomBarChartComponent],
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.scss',
})
export class SummaryComponent implements OnDestroy {
  chartColorsEnum = ChartTypeColorsEnum;
  private readonly unsubscribe$ = new Subject();

  data!: IReportSummary;

  constructor(
    private readonly reportFilterService: ReportFilterService,
    private readonly reportService: ReportService,
    private readonly loadingService: LoadingService
  ) {
    effect(() => this.getReportData(reportFilterService.getFilter()), {
      allowSignalWrites: true,
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  getReportData(filter: IReportFilter | null): void {
    if (!filter) return;
    this.loadingService.showLoading();

    this.reportService
      .getReport(filter)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.data = res.data;
        this.loadingService.closeLoading();
      });
  }
}
