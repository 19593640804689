import { Injectable, signal } from '@angular/core';

import { jwtDecode } from 'jwt-decode';
import { AppStorage } from '../../utils/interfaces/app-storage';
import { IToken } from '../../utils/interfaces/token';

@Injectable({
  providedIn: 'root',
})
export class SessionTokenService {
  private readonly token = signal<any>(null);

  constructor(private readonly appStorage: AppStorage) {
    this.token.set(this.appStorage.get() ?? null);
  }

  isAuthenticatedTokenIsValid(): boolean {
    const token = this.token() ? this.decodeToken() : null;
    if (!token?.exp || new Date(token.exp * 1000) < new Date()) {
      this.closeSession();
      return false;
    }

    return true;
  }

  getToken(): string {
    return this.token();
  }

  setToken(token: string, isLoggedOffice365: boolean = false): void {
    this.token.set(token);
    this.appStorage.set(token);

    localStorage.setItem('isLoggedOffice365', isLoggedOffice365.toString());
  }

  getIsLoggedOffice365(): boolean {
    return localStorage.getItem('isLoggedOffice365') === 'true';
  }

  closeSession(): void {
    this.token.set(null);
    this.appStorage.removeToken();
  }

  decodeToken(): IToken {
    const token: any = jwtDecode(this.token());
    if (token.BranchesId) {
      token.BranchesId = JSON.parse(token.BranchesId);
    }
    return token;
  }
}
