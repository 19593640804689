import { CommonModule } from '@angular/common';
import {
  Component,
  input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { Subject, takeUntil } from 'rxjs';
import { IReportFilter } from '../../../../utils/interfaces/report';
import { IUnit } from '../../../../utils/interfaces/unit';

@Component({
  selector: 'app-cr-unit',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatSelectModule,
  ],
  templateUrl: './cr-unit.component.html',
  styleUrl: './cr-unit.component.scss',
})
export class CrUnitComponent implements OnInit, OnDestroy, OnChanges {
  private readonly unsubscribe$ = new Subject();

  filter = input.required<IReportFilter>();
  allUnitsToFilter = input.required<IUnit[]>();

  units: IUnit[] = [];
  selectedUnits: IUnit[] = [];

  allUnitsSelectedFilter: IUnit[] = [];

  allUnits: IUnit[] = [];

  filterUnits: FormControl = new FormControl<string | null>(null);
  filterSelectedUnits: FormControl = new FormControl<string | null>(null);

  moveToSelected: string[] = [];
  moveToUnselected: string[] = [];

  ngOnInit(): void {
    this.filterUnits.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (!value) {
          this.units = this.allUnits;
          return;
        }

        this.units = this.allUnits.filter(
          (unit) =>
            unit.name.toLowerCase().includes(value?.toLowerCase()) ||
            unit.code.includes(value)
        );
      });

    this.filterSelectedUnits.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (!value) {
          this.selectedUnits = this.allUnitsSelectedFilter;
          return;
        }

        this.selectedUnits = this.allUnitsSelectedFilter.filter(
          (unit) =>
            unit.name.toLowerCase().includes(value.toLowerCase()) ||
            unit.code.includes(value)
        );
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['filter'].previousValue) {
      this.allUnits = this.allUnitsToFilter();
      this.units = this.allUnits;
    }

    if (this.filter().branchesId.length) {
      this.moveToSelected = this.filter().branchesId;

      this.onSelected();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  onMoveToSelected(id: string): void {
    if (this.moveToSelected.includes(id)) {
      this.moveToSelected = this.moveToSelected.filter((item) => item !== id);
    } else {
      this.moveToSelected.push(id);
    }
    this.filter().profileUserId = '';
  }

  onMoveToUnselected(id: string): void {
    if (this.moveToUnselected.includes(id)) {
      this.moveToUnselected = this.moveToUnselected.filter(
        (item) => item !== id
      );
    } else {
      this.moveToUnselected.push(id);
    }
    this.filter().profileUserId = '';
  }

  onSelected(): void {
    this.selectedUnits = [
      ...this.selectedUnits,
      ...this.units.filter((unit) => this.moveToSelected.includes(unit.id)),
    ];

    this.units = this.units.filter(
      (unit) => !this.moveToSelected.includes(unit.id)
    );

    this.allUnits = this.allUnits.filter(
      (unit) => !this.moveToSelected.includes(unit.id)
    );

    this.allUnitsSelectedFilter = this.selectedUnits;
    this.moveToSelected = [];

    this.filter().branchesId = this.selectedUnits.map((unit) => unit.id);
    this.filter().profileUserId = '';
  }

  onUnselected(): void {
    const move = JSON.parse(JSON.stringify(this.moveToUnselected));

    this.units = [
      ...this.units,
      ...this.selectedUnits.filter((unit) => move.includes(unit.id)),
    ];

    this.selectedUnits = this.selectedUnits.filter(
      (unit) => !move.includes(unit.id)
    );

    this.allUnitsSelectedFilter = this.allUnitsSelectedFilter.filter(
      (unit) => !move.includes(unit.id)
    );

    this.allUnits = this.units;
    this.moveToUnselected = [];

    this.filter().branchesId = this.selectedUnits.map((unit) => unit.id);
    this.filter().profileUserId = '';
  }

  selectAll(): void {
    this.selectedUnits = [...this.selectedUnits, ...this.units];
    this.allUnitsSelectedFilter = this.selectedUnits;

    const unitIds: string[] = this.units.map((unit) => unit.id);

    this.allUnits = this.allUnits.filter((item) => !unitIds.includes(item.id));

    this.units = [];
    this.filterUnits.reset();
    this.filterSelectedUnits.reset();

    this.filter().branchesId = this.selectedUnits.map((unit) => unit.id);
    this.filter().profileUserId = '';

    this.moveToSelected = [];
  }

  unselectedAll(): void {
    this.units = [...this.units, ...this.selectedUnits];
    this.allUnits = [...this.units];

    const unitIds: string[] = this.selectedUnits.map((unit) => unit.id);

    this.allUnitsSelectedFilter = this.allUnitsSelectedFilter.filter(
      (item) => !unitIds.includes(item.id)
    );

    this.selectedUnits = [];
    this.filterUnits.reset();
    this.filterSelectedUnits.reset();
    this.filter().branchesId = this.selectedUnits.map((unit) => unit.id);
    this.filter().profileUserId = '';

    this.moveToUnselected = [];
  }
}
