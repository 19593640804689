import { CommonModule } from '@angular/common';
import { Component, inject, input, OnDestroy } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { IBreadcrumb } from '../../../utils/interfaces/breadcrumb';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
})
export class BreadcrumbComponent implements OnDestroy {
  private readonly router = inject(Router);
  private readonly dialog = inject(MatDialog);

  breadcrumb = input.required<IBreadcrumb[]>();
  askBeforeChangingRoute = input<boolean>(false);

  private readonly unsubscribe$ = new Subject();

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  checkRedirectTo(item: IBreadcrumb): void {
    if (!item.url) return;

    if (item.askBeforeChangingRoute) {
      if (!item.textTitle || !item.descriptionMsg) {
        throw new Error(
          'É necessário informar o título e a descrição para a confirmação de mudança de rota'
        );
      } else {
        const dialogRef = this.dialog.open(ConfirmationModalComponent, {
          width: '500px',
          data: {
            title: item.textTitle,
            description: item.descriptionMsg,
          },
        });

        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((result) => {
            if (result) this.redirectTo(item.url);
          });
      }
    } else {
      this.redirectTo(item.url);
    }
  }

  redirectTo(url: string): void {
    this.router.navigate([url]);
  }
}
