import { Routes } from '@angular/router';

import {
  getPollResolver,
  getPollsResolver,
} from '../../shared/services/resolvers/get-poll.resolver';
import { ListComponent } from './list/list.component';
import { PollComponent } from './poll/poll.component';

export const PollsRoutes: Routes = [
  {
    path: '',
    redirectTo: 'list',
    pathMatch: 'full',
  },
  {
    path: 'list',
    resolve: {
      polls: getPollsResolver,
    },
    loadComponent: () => ListComponent,
  },
  {
    path: 'new-poll',
    title: 'Nova Pesquisa',
    loadComponent: () => PollComponent,
  },
  {
    path: 'edit-poll/:id',
    title: 'Editar Pesquisa',
    resolve: {
      poll: getPollResolver,
    },
    loadComponent: () => PollComponent,
  },
];
