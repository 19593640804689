<div class="content">
  <div class="container">
    <p class="title">Esqueci a senha</p>
    <form [formGroup]="form">
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input
          matInput
          placeholder="Ex. email@email.com"
          formControlName="email"
        />
        @if (form.controls.email.hasError('email') &&
        !form.controls.email.hasError('required')) {
        <mat-error>Por favor insira um endereço de e-mail válido</mat-error>
        } @if (form.controls.email.hasError('required')) {
        <mat-error>Email é obrigatório</mat-error>
        }
      </mat-form-field>
    </form>
    <p class="description">
      Enviaremos um link de redefinição para o e-mail informado, caso ele esteja
      associado a uma conta da Sapore.
    </p>

    <button class="btn danger" [disabled]="form.invalid" (click)="onSubmit()">
      Avançar
    </button>

    <p class="back" routerLink="../../auth"><span>Voltar</span></p>
  </div>
</div>
