<table>
  <thead>
    <tr>
      @for (header of tableHeader(); track $index; let first = $first; let last
      = $last) {
      <td [ngClass]="{ first: first, last: last }">
        {{ header.description }}

        @if (!header.actions) {
        <img
          src="assets/img/arrows.png"
          alt="Sort"
          (click)="sortList(header)"
        />
        }
      </td>
      }
    </tr>
  </thead>
  <tbody>
    @for (item of list(); track item.id) {
    <tr>
      @for (header of tableHeader(); track $index) { @if (header.status) {
      <td>
        @if (withStatusDescription()) {
        <p class="status" [ngClass]="{ active: item[header.propertyKey] }">
          {{ item[header.propertyKey] ? "Ativo" : "Inativo" }}
        </p>
        }@else {
        <mat-slide-toggle
          [(ngModel)]="item[header.propertyKey]"
          (ngModelChange)="onChangeStatus.emit(item.id)"
        ></mat-slide-toggle>
        }
      </td>
      } @else if(header.date ) {
      <td>
        @if (item[header.propertyKey]) {
        {{ item[header.propertyKey] | date : "dd/MM/yyyy" }}
        }@else { - }
      </td>
      } @else if(header.actions ) {
      <td>
        @if (!imgForActions()) {
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          aria-label="Example icon-button with a menu"
        >
          <mat-icon>settings</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          @for (action of actionList(); track action) {
          <button
            mat-menu-item
            (click)="
              onSelectAction.emit({
                id: item.id,
                func: action
              })
            "
          >
            {{ action }}
          </button>
          } @empty { É necessário passar uma lista de ações para a tabela }
        </mat-menu>
        } @else {
        <img
          [src]="imgForActions()"
          alt="Zoio"
          (click)="
            onSelectAction.emit({
              id: item.id,
              func: actionList()[0]
            })
          "
        />
        }
      </td>
      } @else {
      <td>{{ getNestedValue(item, header.propertyKey) }}</td>
      } }
    </tr>
    } @empty {
    <tr>
      <td [attr.colspan]="tableHeader().length">Não há itens para exibir</td>
    </tr>
    }
  </tbody>
</table>
