import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { IResponse } from '../../../utils/interfaces/response';
import { IUnit, IUnitList } from '../../../utils/interfaces/unit';
import { UnitsService } from '../units.service';

export const getUnitsResolver: ResolveFn<IResponse<IUnitList[]>> = (
  route,
  state
) => {
  const unitsService = inject(UnitsService);
  return unitsService.getUnitsFilter(false, '', 1, 10);
};

export const getUnitResolver: ResolveFn<IResponse<IUnit>> = (route, state) => {
  const unitsService = inject(UnitsService);
  return unitsService.getUnitById(route.params['id']);
};

export const getUnitUserPollResolver: ResolveFn<IResponse<IUnit>> = (
  route,
  state
) => {
  const unitsService = inject(UnitsService);
  return unitsService.getUnitUserPollById(route.params['id']);
};
