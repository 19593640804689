import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import {
  IPaginationValue,
  PaginationComponent,
} from '../../../shared/components/pagination/pagination.component';
import { TableComponent } from '../../../shared/components/table/table.component';
import { CustomersService } from '../../../shared/services/customers.service';
import { LoadingService } from '../../../shared/services/loading.service';
import { ICustomer } from '../../../utils/interfaces/customer';
import { IResponse } from '../../../utils/interfaces/response';
import { ITableHeader } from '../../../utils/interfaces/table-header';
import { CnpjFormatPipe } from '../../../utils/pipes/cnpj-format.pipe';

@Component({
  selector: 'app-customers',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TableComponent,
    PaginationComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './customers.component.html',
  styleUrl: './customers.component.scss',
})
export class CustomersComponent implements OnInit, OnDestroy {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly customersService = inject(CustomersService);
  private readonly loadingService = inject(LoadingService);
  private readonly unsubscribe$ = new Subject();

  searchText = new FormControl('');

  tableHeader: ITableHeader[] = [
    {
      description: 'CNPJ',
      propertyKey: 'cnpj',
      status: false,
      date: false,
      actions: false,
      filter: false,
    },
    {
      description: 'Razão Social',
      propertyKey: 'name',
      status: false,
      date: false,
      actions: false,
      filter: false,
    },
  ];

  list: ICustomer[] = [];

  pagination = {
    page: 1,
    take: 10,
  };

  itemCount = 0;

  ngOnInit(): void {
    const { data, count } = this.activatedRoute.snapshot.data[
      'customers'
    ] as IResponse<ICustomer[]>;

    this.list = this.cnpjFormat(data);

    this.itemCount = count ?? 0;

    this.searchText.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(debounceTime(700))
      .subscribe(() => this.getCustomers(true));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  cnpjFormat(customers: ICustomer[]): ICustomer[] {
    const cnpjFormatPipe = new CnpjFormatPipe();

    return customers.map((customer) => {
      customer.cnpj = cnpjFormatPipe.transform(customer.cnpj);
      return customer;
    });
  }

  getCustomers(search = false): void {
    this.loadingService.showLoading();

    this.customersService
      .getCustomers(
        search,
        this.searchText.value?.replace(/[^\w\s]/gi, '').trim() ?? '',
        this.pagination.page,
        this.pagination.take
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.list = this.cnpjFormat(res.data);
        this.itemCount = res.count ?? 0;

        if (search) {
          this.pagination.page = 1;
        }

        this.loadingService.closeLoading();
      });
  }

  onPaginationChange(event: IPaginationValue): void {
    this.pagination = event;

    this.getCustomers();
  }
}
