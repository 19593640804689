import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { IQuestionForm } from '../../../../../utils/interfaces/poll';
import { SelectionComponent } from './selection/selection.component';
import { TextFieldComponent } from './text-field/text-field.component';

@Component({
  selector: 'app-type-of-poll',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    DragDropModule,
    SelectionComponent,
    TextFieldComponent,
    MatSliderModule,
    MatSlideToggleModule,
  ],
  templateUrl: './type-of-poll.component.html',
  styleUrl: './type-of-poll.component.scss',
})
export class TypeOfPollComponent {
  firstPage = input.required<boolean>();
  firstQuestion = input.required<boolean>();

  formQuestion = input.required<FormGroup<IQuestionForm>>();

  onCreatedAnswer = output<{
    question: FormGroup<IQuestionForm>;
    weight: 1 | 2 | 3 | 4 | null;
    required: boolean;
  }>();

  changeQuestionType(type: number) {
    this.formQuestion().controls.answers.clear();
    if (type === 1 || type === 2) {
      for (let i = 0; i < 2; i++) {
        this.onCreatedAnswer.emit({
          question: this.formQuestion(),
          weight: null,
          required: true,
        });
      }
    } else {
      this.onCreatedAnswer.emit({
        question: this.formQuestion(),
        weight: null,
        required: false,
      });
    }
  }
}
