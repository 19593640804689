export const configQuery = (
  search: boolean,
  searchString: string,
  page: number = 0,
  take: number = 0,
  status: boolean | null = null
): string => {
  let params = '?Asc=true';

  if (searchString) {
    params = params.concat(`&SearchString=${searchString}`);
  }

  if (page && take) {
    params = params.concat(`&Page=${search ? '1' : page}&Take=${take}`);
  }

  if (status !== null) {
    params = params.concat(`&Status=${status}`);
  }
  return params;
};
