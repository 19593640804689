import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { LoadingService } from './../../shared/services/loading.service';

import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { jwtDecode } from 'jwt-decode';
import { AlertService } from '../../shared/services/alert.service';
import { UserService } from '../../shared/services/user.service';

@Component({
  selector: 'app-recover-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
  ],
  templateUrl: './recover-password.component.html',
  styleUrl: './recover-password.component.scss',
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject();

  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly userService = inject(UserService);
  private readonly loadingService = inject(LoadingService);
  private readonly router = inject(Router);
  private readonly alertService = inject(AlertService);

  hide = true;
  hide2 = true;

  errors = {
    minCharacters: true,
    uppercase: true,
    lowercase: true,
    number: true,
    specialCharacter: true,
  };

  inputError = false;

  form!: FormGroup;

  ngOnInit(): void {
    this.form = new FormGroup({
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [
        Validators.required,
        this.passwordsMatchValidator.bind(this),
      ]),
    });

    this.form.controls['password'].valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.checkError(value ?? '');

        if (this.form.controls['confirmPassword'].value) {
          this.form.controls['confirmPassword'].updateValueAndValidity();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  checkError(value: string): void {
    this.errors = {
      minCharacters: value.length < 8,
      uppercase: !/[A-Z]/.test(value),
      lowercase: !/[a-z]/.test(value),
      number: !/[0-9]/.test(value),
      specialCharacter: !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value),
    };

    this.inputError = false;

    for (const error in this.errors) {
      if (this.errors[error as keyof typeof this.errors]) {
        this.inputError = true;
      }
    }
  }

  passwordsMatchValidator(
    control: FormControl
  ): { [key: string]: boolean } | null {
    const newPassword = this.form?.controls['password'].value || null;
    return control.value === newPassword ? null : { passwordsMismatch: true };
  }

  changePassword(): void {
    this.loadingService.showLoading();

    const { id }: { id: string } = jwtDecode(
      this.activatedRoute.snapshot.params['token']
    );

    const data = { ...this.form.getRawValue(), userId: id };

    this.userService
      .resetpassword(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.alertService.success(res.message);
        this.loadingService.closeLoading();

        this.router.navigate(['/auth']);
      });
  }
}
