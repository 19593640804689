<h1 mat-dialog-title>Vínculo de respostas com as páginas</h1>
<mat-dialog-content>
  <p>
    As perguntas de seleção única podem ter respostas que levam a outras
    páginas. Se o usuário escolher uma resposta vinculada, será direcionado para
    essa página. Se a resposta não estiver vinculada, o usuário irá para a
    próxima página normalmente. Isso torna a navegação mais clara e fluida.
  </p>

  @for (page of data.form.controls.pages.controls; track page.controls; let
  pageIndex = $index) {

  <div class="card border-left">
    <h3 class="title">Página {{ page.controls.pageNumber.value }}</h3>

    @let question = questionTypeOne(page.controls.questions); @if (question) {

    <div class="question">
      <p>
        {{ question.controls.title.value }}
      </p>

      <div class="answers">
        @for (answer of question.controls.answers.controls; track
        answer.controls; let answerIndex = $index) {
        <div class="answer">
          <div class="form-group">
            <mat-form-field>
              <mat-label>{{ answer.controls.description.value }}</mat-label>
              <mat-select [formControl]="answer.controls.next">
                @for (optionPage of data.form.controls.pages.controls; track
                optionPage.controls; let optionPageIndex = $index) {
                @if(page.controls.pageNumber.value &&
                optionPage.controls.pageNumber.value &&
                (page.controls.pageNumber.value <
                optionPage.controls.pageNumber.value)) {

                <mat-option [value]="optionPage.controls.pageNumber.value"
                  >Página {{ optionPage.controls.pageNumber.value }}</mat-option
                >

                } }
                <mat-option [value]="100"
                  >Página final - agradecimento</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        }
      </div>
    </div>

    } @else {

    <p>Essa página não possui perguntas de seleção única.</p>

    }
  </div>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn danger__no-bg" (click)="onNoClick()">Cancelar</button>
  <button class="btn danger__no-bg" (click)="preview()">
    Visualizar o fluxo
  </button>
  <button class="btn danger" [mat-dialog-close]="data.form.value">
    Concluir
  </button>
</mat-dialog-actions>
