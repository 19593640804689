<div class="page-header">
  <h2>Relatório</h2>
  <button class="btn danger__no-bg">Exportar em PDF</button>
</div>

<div class="card">
  <p routerLinkActive="active" routerLink="summary">Resumo</p>
  <p routerLinkActive="active" routerLink="managementDiary">Diário gerencial</p>
  <p routerLinkActive="active" routerLink="engagement">Engajamento</p>
  <p routerLinkActive="active" routerLink="feedback">Feedback</p>
  <p routerLinkActive="active" routerLink="evolution">Evolução</p>
  <p routerLinkActive="active" routerLink="analytical">Analítico</p>
  <p routerLinkActive="active" routerLink="wordcloud">Wordcloud</p>
  <p routerLinkActive="active" routerLink="meter">Medidor</p>
  <button class="btn danger__no-bg" (click)="openFilterDialog()">
    Filtros
  </button>
</div>

<router-outlet />
