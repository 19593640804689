<div class="content">
  <div class="container">
    <p class="title">
      Seu cadastro não possui uma unidade ou perfil associado ao seu usuário.
    </p>
    <p class="description">
      Entre em contato com o suporte para solicitar a adição de uma unidade ou
      para vincular um perfil ao seu.
    </p>

    <div class="help">
      <a href="https://suporte.sapore.com.br/" target="_blank" rel="noopener"
        >Entre em contato</a
      >
    </div>

    <p class="back" routerLink="../../auth">
      <span>Voltar</span>
    </p>
  </div>
</div>
