import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { SessionTokenService } from '../session-token.service';

export const authGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  /**
    Verificar se o usuário está autenticado
   */
  const isAuthenticated =
    inject(SessionTokenService).isAuthenticatedTokenIsValid();

  if (isAuthenticated) inject(Router).navigate(['main']);

  return !isAuthenticated;
};
