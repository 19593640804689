import { Routes } from '@angular/router';

import { authGuard } from '../shared/services/guards/auth.guard';
import { checkPasswordChangeRequestGuard } from '../shared/services/guards/check-password-change-request.guard';
import { AuthComponent } from './auth.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { WithoutPermissionComponent } from './without-permission/without-permission.component';

export const authRoutes: Routes = [
  {
    path: '',
    loadComponent: () => AuthComponent,
    children: [
      {
        path: '',
        redirectTo: 'sign-in',
        pathMatch: 'full',
      },
      {
        path: 'sign-in',
        title: 'Login',
        canActivate: [authGuard],
        loadComponent: () => SignInComponent,
      },
      {
        path: 'forgot-password',
        title: 'Recupperar Senha',
        canActivate: [authGuard],
        loadComponent: () => ForgotPasswordComponent,
      },
      {
        path: 'recover-password/:token',
        title: 'Trocar a senha',
        canActivate: [authGuard, checkPasswordChangeRequestGuard],
        loadComponent: () => RecoverPasswordComponent,
      },
      {
        path: 'without-permission',
        title: 'Cadastro incompleto',
        loadComponent: () => WithoutPermissionComponent,
      },
    ],
  },
];
