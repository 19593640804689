import { Routes } from '@angular/router';

import { getPollsResolver } from '../../shared/services/resolvers/get-poll.resolver';
import { ListOfReportsComponent } from './list-of-reports/list-of-reports.component';
import { reportDataRoutes } from './report-data/report-data.routes';
import { ReportComponent } from './report.component';

export const reportRoutes: Routes = [
  {
    path: '',
    loadComponent: () => ReportComponent,
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        title: 'Relatórios',
        resolve: {
          polls: getPollsResolver,
        },
        loadComponent: () => ListOfReportsComponent,
      },
      {
        path: 'data',
        loadChildren: () => reportDataRoutes,
      },
    ],
  },
];
