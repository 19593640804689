<div class="header-survey">
  <img class="logo" src="assets/svg/logo-red.svg" alt="logo" />

  <div class="btns">
    @if (step) {
    <div>
      <img
        title="Ver o fluxo da pesquisa"
        src="assets/img/view.png"
        alt="View"
        (click)="preview()"
      />
    </div>
    }

    <button class="btn danger__no-bg" (click)="exitPage()">Sair</button>
    @if (step) {
    <button class="btn danger" (click)="pageAssignmentDialog()">Salvar</button>
    } @else {
    <button
      class="btn danger__no-bg"
      [disabled]="!form.controls.numberResponses.value"
      (click)="this.handlerPage()"
    >
      Próximo >
    </button>
    }
  </div>
</div>

@if (!step) {
<app-quantity-answers [count]="form.controls.numberResponses" />
} @else {
<app-poll-configuration
  [form]="form"
  (onAddQuestion)="handlerQuestion($event)"
  (onCreatedAnswer)="handlerAnswer($event)"
  (onDeleteQuestion)="deleteQuestion($event)"
  (onCreatedPage)="handlerPage()"
  (onDeletePage)="deletePage($event)"
/>
}
