import { Component, effect, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { SessionTokenService } from './../../services/session-token.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject();
  unique_name = '';
  role = '';
  photo = '';

  shortName = '';

  constructor(
    private readonly router: Router,
    private readonly sessionTokenService: SessionTokenService,
    private readonly authService: AuthService
  ) {
    effect(() => this.redirectLogin(sessionTokenService.getToken()));
  }

  ngOnInit(): void {
    const { unique_name, role, Photo } = this.sessionTokenService.decodeToken();

    this.unique_name = unique_name;
    this.role = role;
    this.photo = Photo;

    this.shortName = this.unique_name
      .split(' ')
      .map((name) => name[0])
      .join('')
      .substring(0, 2);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  redirectLogin(token: string | null): void {
    if (!token) this.router.navigate(['auth']);
  }

  logout(): void {
    const closeSession = () => {
      this.sessionTokenService.closeSession();
      this.router.navigate(['auth']);
    };

    if (this.sessionTokenService.getIsLoggedOffice365())
      this.authService
        .logoutOffice365()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          closeSession();
        });
    else closeSession();
  }
}
