import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { IReportFilter } from '../../../../utils/interfaces/report';

@Component({
  selector: 'app-days-week',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './days-week.component.html',
  styleUrl: './days-week.component.scss',
})
export class DaysWeekComponent {
  filter = input.required<IReportFilter>();

  weekDay: { id: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7; day: string }[] = [
    {
      id: 2,
      day: 'Segunda',
    },
    {
      id: 3,
      day: 'Terça',
    },
    {
      id: 4,
      day: 'Quarta',
    },
    {
      id: 5,
      day: 'Quinta',
    },
    {
      id: 6,
      day: 'Sexta',
    },
    {
      id: 7,
      day: 'Sábado',
    },
    {
      id: 1,
      day: 'Domingo',
    },
  ];

  selectedDay: number | null = null;

  selectDayWeek(day: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7): void {
    if (this.filter().weekDay === day) {
      this.filter().weekDay = 0;
      return;
    }

    this.filter().weekDay = day;
  }
}
