<div class="dialog-title" mat-dialog-title>
  <p>Filtros</p>
  <mat-icon mat-dialog-close>close</mat-icon>
</div>
<mat-dialog-content class="mat-typography">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title> Avaliação </mat-panel-title>
      </mat-expansion-panel-header>

      <app-assessment [filter]="filter()!" />
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> CR / Unidade </mat-panel-title>
      </mat-expansion-panel-header>

      <app-cr-unit [filter]="filter()!" [allUnitsToFilter]="data.units" />
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Datas </mat-panel-title>
      </mat-expansion-panel-header>

      <app-filter-date [filter]="filter()!" />
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Dia da semana </mat-panel-title>
      </mat-expansion-panel-header>

      <app-days-week [filter]="filter()!" />
    </mat-expansion-panel>

    @if (filter()?.branchesId?.length === 1 ) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Turnos </mat-panel-title>
      </mat-expansion-panel-header>

      <app-shifts [filter]="filter()!" [allUnits]="data.units" />
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Horários </mat-panel-title>
      </mat-expansion-panel-header>

      <app-schedules [filter]="filter()!" />
    </mat-expansion-panel>
    }

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Resposta </mat-panel-title>
      </mat-expansion-panel-header>

      <app-poll-filter [poll]="data.poll" [filter]="filter()!" />
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn danger__no-bg" (click)="clearFilter()">Limpar</button>
  <button class="btn danger" (click)="setFilter()">Aplicar</button>
</mat-dialog-actions>
