import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { IPoll } from '../../../../utils/interfaces/poll';
import { IReportFilter } from '../../../../utils/interfaces/report';

@Component({
  selector: 'app-poll-filter',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule],
  templateUrl: './poll-filter.component.html',
  styleUrl: './poll-filter.component.scss',
})
export class PollFilterComponent {
  poll = input.required<IPoll>();
  filter = input.required<IReportFilter>();
}
