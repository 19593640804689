<div class="header">
  <img class="logo" src="assets/svg/logo-red.svg" alt="logo" />
</div>

<mat-dialog-content>
  @if (page) { Página: {{ page.pageNumber }}
  @for (question of page.questions; track question.number) {

  <div class="tab">
    <p class="statement">{{ question.title }}</p>

    <div class="content">
      @for (answer of question.answers; track $index) { @if(question.type === 1)
      {

      <div
        class="answer"
        [ngClass]="{
          active: question.value?.includes($index),
          'type-1-no-img': !answer.img
        }"
        (click)="
          addAnswerType1(question, $index);
          selectUniqueAnswer(page, answer.next)
        "
      >
        @if (answer.img){
        <img [src]="answer.img" alt="IMG" />
        }
        <p class="answer-text">{{ answer.description }}</p>
      </div>

      }@else if (question.type === 2) {

      <div
        class="answer"
        [ngClass]="{
          active: question.value?.includes($index),
          'type-2-no-img': !answer.img
        }"
        (click)="addAnswerType2(question, $index); selectUniqueAnswer(page)"
      >
        @if (answer.img){
        <img [src]="answer.img" alt="IMG" />
        }
        <p class="answer-text">{{ answer.description }}</p>
      </div>
      } @else {
      <mat-form-field class="example-full-width">
        <mat-label>Deixe seu comentário</mat-label>
        <textarea
          matInput
          placeholder="Ex. Gostei muito..."
          [(ngModel)]="question.value"
          (ngModelChange)="checkDisabled(page)"
        ></textarea>
      </mat-form-field>
      } }
    </div>
  </div>
  } @if (showBtn) {
  <button
    class="btn danger"
    (click)="selectPage(selectedIndex + 1)"
    [disabled]="disabled"
  >
    Enviar
  </button>
  } } @else {

  <div class="end-page">
    @if ( data.formValue.endPage.img ) {
    <img [src]="data.formValue.endPage.img" alt="dialog" />
    } @else {
    <img src="assets/img/dialog.png" alt="dialog" />
    }
    <p class="title">{{ data.formValue.endPage.title }}</p>
    <p class="description">{{ data.formValue.endPage.description }}</p>
  </div>

  } @if(page && page.pageNumber !== 1 && page.showTimer && page.timer) {

  <div class="timer">
    <p>Tempo para responder: {{ time() }} seg.</p>
  </div>

  }@else if(!page && data.formValue.endPage.showTimer) {

  <div class="timer">
    <p>Timer: {{ time() }} seg.</p>
  </div>

  }@else{
  <div class="timer">
    <p>O timer está oculto.</p>
  </div>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn danger__no-bg" (click)="dialogRef.close()">Fechar</button>
</mat-dialog-actions>
