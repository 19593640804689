<app-breadcrumb [breadcrumb]="breadcrumb" />
<h2>Detalhes da unidade</h2>
<div class="card">
  <form [formGroup]="form">
    <div class="input-forms">
      <mat-form-field>
        <mat-label>CR/Unidade</mat-label>
        <input matInput placeholder="Digite aqui..." formControlName="code" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Nome</mat-label>
        <input matInput placeholder="Digite aqui..." formControlName="name" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Regional</mat-label>
        <input matInput placeholder="Digite aqui..." formControlName="region" />
      </mat-form-field>
    </div>
    <div class="input-forms">
      <mat-form-field>
        <mat-label>Status</mat-label>
        <input
          matInput
          placeholder="Digite aqui..."
          [value]="form.controls.active ? 'Ativo' : 'Desativado'"
          disabled
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Cliente</mat-label>
        <input
          matInput
          placeholder="Digite aqui..."
          formControlName="customerName"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Pesquisa</mat-label>
        <mat-select formControlName="pollId">
          @for (poll of polls; track $index) {
          <mat-option [value]="poll.id">{{ poll.title }}</mat-option>
          }
        </mat-select>
        @if (form.controls.pollId.hasError('required')) {
        <mat-error>Pesquisa é obrigatório</mat-error>
        }
      </mat-form-field>
    </div>

    <div class="cards-data" [formGroup]="formNewAvailableTime">
      <p>Turnos e horários que a pesquisa ficará disponível</p>

      <div class="inputs-card">
        <div class="input-forms inputs-time">
          <mat-form-field>
            <mat-label>Descrição do horário</mat-label>
            <input
              matInput
              placeholder="EX: Café da manhã."
              formControlName="description"
            />
            @if (formNewAvailableTime.controls.description.hasError('required'))
            {
            <mat-error>Descrição do horário é obrigatório</mat-error>
            }
          </mat-form-field>

          <mat-form-field>
            <mat-label>Horário de início</mat-label>
            <input
              matInput
              type="time"
              placeholder="EX: 12:00"
              formControlName="startTime"
            />
            @if (formNewAvailableTime.controls.startTime.hasError('required')) {
            <mat-error>Horário de início é obrigatório</mat-error>
            }
          </mat-form-field>

          <mat-form-field>
            <mat-label>Horário de término</mat-label>
            <input
              matInput
              type="time"
              placeholder="EX: 15:00"
              formControlName="endTime"
            />
            @if (formNewAvailableTime.controls.endTime.hasError('required')) {
            <mat-error>Horário de término é obrigatório</mat-error>
            } @if
            (formNewAvailableTime.controls.endTime.hasError('invalidTimeRange'))
            {
            <mat-error
              >Horário de término deve ser maior que o de início</mat-error
            >
            }
          </mat-form-field>

          <div class="btn-add">
            <img
              src="assets/img/add.png"
              alt="add"
              (click)="validateAddAvailableTime()"
            />
          </div>
        </div>
        @for (formAvailableTimes of form.controls.availableShifts.controls;
        track $index) {
        <div class="input-forms inputs-time">
          <mat-form-field>
            <mat-label>Descrição do horário</mat-label>
            <input
              matInput
              placeholder="EX: Café da manhã."
              [formControl]="formAvailableTimes.controls.description"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Horário de início</mat-label>
            <input
              matInput
              type="time"
              placeholder="EX: 12:00"
              [formControl]="formAvailableTimes.controls.startTime"
            />
          </mat-form-field>

          <mat-form-field>
            <mat-label>Horário de término</mat-label>
            <input
              matInput
              type="time"
              placeholder="EX: 15:00"
              [formControl]="formAvailableTimes.controls.endTime"
            />
          </mat-form-field>

          <div class="btn-add">
            <img
              src="assets/img/delete.png"
              alt="add"
              (click)="deleteAvailableTime($index)"
            />
          </div>
        </div>
        }
      </div>
    </div>

    <div class="cards-data">
      <p>Usuários</p>

      <div class="inputs-card">
        <mat-form-field class="searchUsers">
          <mat-label>Procurar usuários</mat-label>
          <input
            type="text"
            placeholder="Procurar usuários por nome ou e-mail..."
            aria-label="Usuários"
            matInput
            [formControl]="searchUser"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            @if (loadingUsers) {
            <mat-option disabled>Carregando...</mat-option>
            } @else if(!loadingUsers && isSearching && searchUser.value &&
            !usersList.length) {
            <mat-option disabled>Nenhum resultado encontrado</mat-option>
            } @for (option of usersList; track option.id) {

            <mat-option (click)="checkAddUser(option)">
              {{ option.name }} - {{ option.email }}
            </mat-option>

            }
          </mat-autocomplete>
        </mat-form-field>

        @for (user of form.controls.usersBranch.controls; track user.controls) {
        <div class="users border">
          <div>
            <p>
              {{ user.controls.userName.value }}
            </p>
            <p>
              {{ user.controls.userEmail.value }}
            </p>
          </div>

          <img
            src="assets/img/delete.png"
            alt="add"
            (click)="deleteUser(user.controls.userId.value!)"
          />
        </div>
        } @empty {
        <div class="empty">
          <p>Não existe usuários nesta unidade</p>
        </div>
        }
      </div>
    </div>

    <div class="cards-data">
      <p>Dispositivos</p>

      <div class="inputs-card">
        <div class="add-device">
          <mat-form-field>
            <mat-label>Nome do novo dispositivo</mat-label>
            <input matInput [formControl]="newDevice" />
            @if (newDevice.hasError('required')) {
            <mat-error>Nome do novo dispositivo é obrigatório</mat-error>
            }
          </mat-form-field>
          <div class="btn-add">
            <img src="assets/img/add.png" alt="add" (click)="addDevice()" />
          </div>
        </div>

        @for (device of form.controls.devices.controls; track $index) {
        <div class="users">
          <mat-form-field>
            <mat-label>Nome do dispositivo</mat-label>
            <input matInput [formControl]="device.controls.name" />
            @if (device.controls.name.hasError('required')) {
            <mat-error>Nome do novo dispositivo é obrigatório</mat-error>
            }
          </mat-form-field>

          <div class="btn-add">
            <img
              class="edit"
              src="assets/img/edit.png"
              alt="add"
              (click)="editDevice($index)"
            />
            <img
              src="assets/img/delete.png"
              alt="delete"
              (click)="deleteDevice($index)"
            />
          </div>
        </div>
        } @empty {
        <div class="empty">
          <p>Não existe dispositivos cadastrados</p>
        </div>
        }
      </div>
    </div>
  </form>

  <div class="update">
    <button class="btn danger__no-bg" (click)="cancel()">Cancelar</button>
    <button class="btn danger" (click)="updateUnit()">Atualizar</button>
  </div>
</div>
