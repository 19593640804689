import { Component, input, output } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ITableHeader } from '../../../utils/interfaces/table-header';

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent {
  tableHeader = input.required<ITableHeader[]>();
  list = input.required<any[]>();
  actionList = input<string[]>([]);
  imgForActions = input<string>();
  withStatusDescription = input<boolean>(false);

  onChangeStatus = output<string>();
  onSelectAction = output<{ id: string; func: string }>();

  sortList(key: ITableHeader): void {
    if (!this.list()?.length) return;

    if (key.filter) {
      this.list().sort((a, b) =>
        a[key.propertyKey] > b[key.propertyKey] ? 1 : -1
      );
    } else
      this.list().sort((a, b) =>
        b[key.propertyKey] > a[key.propertyKey] ? 1 : -1
      );

    key.filter = !key.filter;
  }

  getNestedValue(obj: any, key: string): string {
    return key.split('.').reduce((o, k) => o?.[k], obj);
  }
}
