import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { SessionTokenService } from '../session-token.service';

export const accountGuard: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  /**
    Verificar se o usuário está autenticado
   */
  const isAuthenticated =
    inject(SessionTokenService).isAuthenticatedTokenIsValid();

  if (!isAuthenticated) inject(Router).navigate(['auth']);

  return isAuthenticated;
};
