<div class="content">
  @for (unit of allUnits(); track unit) { @for (shift of unit.availableShifts;
  track shift) {
  <div class="checkbox">
    <p>{{ shift.description }} {{ shift.startTime }} às {{ shift.endTime }}</p>
    <mat-checkbox
      [checked]="this.filter().avaiableShifts.includes(shift.id!)"
      (click)="handlerShift(shift.id!)"
    ></mat-checkbox>
  </div>
  }@empty {
  <p class="empyt">Nenhum turno disponível</p>
  } }
</div>
