import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private readonly overlay = signal<boolean>(false);

  getLoadingStatus(): boolean {
    return this.overlay();
  }

  showLoading(): void {
    this.overlay.set(true);
  }

  closeLoading(): void {
    this.overlay.set(false);
  }
}
