import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { IPage, IPoll, IQuestion } from '../../../utils/interfaces/poll';

@Component({
  selector: 'app-preview-poll',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatInputModule,
  ],
  templateUrl: './preview-poll.component.html',
  styleUrl: './preview-poll.component.scss',
})
export class PreviewPollComponent implements OnInit {
  readonly dialogRef = inject(MatDialogRef<PreviewPollComponent>);
  readonly data = inject<{ formValue: IPoll }>(MAT_DIALOG_DATA);

  selectedIndex = 0;

  page!: IPage;
  showBtn = false;
  disabled = true;

  time = signal<number>(0);
  interval!: any;

  ngOnInit(): void {
    this.selectPage(0);
  }

  selectPage(index: number): void {
    const pageSelected = this.data.formValue.pages[index];

    if (pageSelected?.questions.length > 1) {
      this.showBtn = true;
    } else if (pageSelected?.questions.some((q) => q.type !== 1)) {
      this.showBtn = true;
    } else {
      this.showBtn = false;
    }

    this.selectedIndex = index;
    this.page = this.data.formValue.pages[index];
    this.checkDisabled(this.page);
    clearInterval(this.interval);
    this.countTime(this.page);
  }

  addAnswerType1(question: IQuestion, index: number): void {
    question.value = [index];
  }

  addAnswerType2(question: IQuestion, index: number): void {
    if (question.value) {
      if (!question.value.includes(index)) question.value.push(index);
      else question.value = question.value.filter((item) => item !== index);
    } else {
      question.value = [index];
    }
  }

  selectUniqueAnswer(page: IPage, next: number | null = null): void {
    if (!this.showBtn) {
      this.selectPage(next ? next - 1 : this.selectedIndex + 1);
    } else {
      this.checkDisabled(page);
    }
  }

  checkDisabled(page: IPage): void {
    let isDisabled = false;
    page?.questions.forEach((Q) => {
      if (Q.requiredAnswer && !Q.value?.length) {
        isDisabled = true;
      }
    });

    this.disabled = isDisabled;
  }

  countTime(page: IPage): void {
    if (page) {
      if (page.pageNumber !== 1 && page.timer > 0) {
        this.time.set(page.timer);

        this.interval = setInterval(() => {
          if (this.time() === 1) {
            clearInterval(this.interval);
            this.selectPage(this.selectedIndex + 1);
          } else {
            this.time.update((value) => value - 1);
          }
        }, 1000);
      }
    } else {
      this.time.set(this.data.formValue.endPage.timer);
      this.interval = setInterval(() => {
        if (this.time() === 1) {
          clearInterval(this.interval);
          this.dialogRef.close();
        } else {
          this.time.update((value) => value - 1);
        }
      }, 1000);
    }
  }
}
