<div class="content">
  <div class="schedules">
    <p>Horário inicial</p>
    <div class="inputs">
      <input type="time" [(ngModel)]="filter().startTime" />
    </div>
  </div>
  <div class="schedules">
    <p>Horário final</p>
    <div class="inputs">
      <input type="time" [(ngModel)]="filter().finishTime" />
    </div>
  </div>
</div>
