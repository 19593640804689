import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from '../../../../environments/environment';
import { IToken } from '../../../utils/interfaces/token';
import { SessionTokenService } from '../session-token.service';

export const hasProfileandBranchGuard: CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const token: IToken = inject(SessionTokenService).decodeToken();
  let hasPermission = !!token.BranchesId?.length && !!token.RoleId;

  if (token.RoleId && token.RoleId === environment.profileADMId) {
    hasPermission = true;
  }

  if (!hasPermission) {
    inject(Router).navigate(['auth/without-permission']);
    inject(SessionTokenService).closeSession();
  }

  return hasPermission;
};
