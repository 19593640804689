import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { configQuery } from '../../utils/config-query';
import { IPoll, IPollToList } from '../../utils/interfaces/poll';
import { IResponse } from '../../utils/interfaces/response';

@Injectable({
  providedIn: 'root',
})
export class PollService {
  private readonly URI_API = environment.apiConfig.uri + 'Poll';

  constructor(private readonly httpClient: HttpClient) {}

  getPolls(
    search: boolean,
    searchString: string = '',
    page: number = 0,
    take: number = 0,
    status: boolean | null = null
  ): Observable<IResponse<IPollToList[]>> {
    return this.httpClient.get<IResponse<IPollToList[]>>(
      `${this.URI_API}/all${configQuery(
        search,
        searchString,
        page,
        take,
        status
      )}`
    );
  }

  getPollById(id: string): Observable<IResponse<IPoll>> {
    return this.httpClient.get<IResponse<IPoll>>(`${this.URI_API}/${id}`);
  }

  createPoll(poll: IPoll): Observable<IResponse<string>> {
    return this.httpClient.post<IResponse<string>>(
      `${this.URI_API}/create`,
      poll
    );
  }

  updatePoll(poll: IPoll): Observable<IResponse<IPoll>> {
    return this.httpClient.put<IResponse<IPoll>>(
      `${this.URI_API}/update`,
      poll
    );
  }

  updatePollStatus(id: string): Observable<IResponse<boolean>> {
    return this.httpClient.put<IResponse<boolean>>(
      `${this.URI_API}/status/${id}`,
      {}
    );
  }

  duplicatePoll(id: string): Observable<IResponse<string>> {
    return this.httpClient.post<IResponse<string>>(
      `${this.URI_API}/duplicate/${id}`,
      {}
    );
  }

  deletePoll(id: string): Observable<IResponse<boolean>> {
    return this.httpClient.delete<IResponse<boolean>>(
      `${this.URI_API}/${id}`,
      {}
    );
  }
}
