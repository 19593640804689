import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { IPoll, IPollToList } from '../../../utils/interfaces/poll';
import { IResponse } from '../../../utils/interfaces/response';
import { PollService } from '../poll.service';

export const getPollsResolver: ResolveFn<IResponse<IPollToList[]>> = () => {
  const pollService = inject(PollService);
  return pollService.getPolls(false, '', 1, 10);
};

export const getAllPollsResolver: ResolveFn<IResponse<IPollToList[]>> = () => {
  const pollService = inject(PollService);
  return pollService.getPolls(false, '', 0, 0, true);
};

export const getPollResolver: ResolveFn<IResponse<IPoll>> = (route) => {
  const pollService = inject(PollService);
  return pollService.getPollById(route.params['id']);
};
