export const environment = {
  production: false,
  storagePrefix: 'session',
  profileADMId: '0ce77a2a-70a4-477d-9720-aada64089436',
  msalConfig: {
    auth: {
      clientId: 'a9d44268-9822-4a7f-8634-26d099306f69',
      authority:
        'https://login.microsoftonline.com/00904a09-510f-4361-a1f5-a5f17193a28e',
    },
  },
  apiConfig: {
    uri: 'https://api-newsurvey-dev.sapore.com.br/',
  },

  redirectUri: 'https://newsurvey-backoffice-dev.sapore.com.br',
  postLogoutRedirectUri: 'https://newsurvey-backoffice-dev.sapore.com.br',
};
