export class AppStorage {
  constructor(
    private readonly storage: Storage,
    private readonly prefix: string
  ) {}

  set(value: string): void {
    this.storage.setItem(this.prefix, value);
  }

  get(): string {
    return this.storage.getItem(this.prefix) ?? '';
  }

  removeToken(): void {
    this.storage.removeItem(this.prefix);
  }
}
