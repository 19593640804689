import { Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { MainComponent } from './main.component';
import { PollsRoutes } from './polls/polls.routes';
import { registrationRoutes } from './registration/registration.routes';
import { reportRoutes } from './report/report.routes';

export const mainRoutes: Routes = [
  {
    path: '',
    loadComponent: () => MainComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        title: 'Home',
        loadComponent: () => HomeComponent,
      },
      {
        path: 'registration',
        title: 'Cadastros',
        loadChildren: () => registrationRoutes,
      },
      {
        path: 'polls',
        title: 'Pesquisas',
        loadChildren: () => PollsRoutes,
      },
      {
        path: 'report',
        title: 'Relatórios',
        loadChildren: () => reportRoutes,
      },
    ],
  },
];
