<div class="breadcrumb">
  @for (item of breadcrumb(); track $index; let last = $last) {

  <p [ngClass]="{ last: $last }" (click)="checkRedirectTo(item)">
    {{ item.description }}
  </p>
  @if (!$last) {
  <img src="assets/img/arrow-right.png" alt="arrow-right" />
  } }
</div>
