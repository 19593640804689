import { CommonModule } from '@angular/common';
import { Component, inject, input, OnDestroy, output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { Subject, takeUntil } from 'rxjs';
import { FileService } from '../../../../shared/services/image-to-base64.service';
import { LoadingService } from '../../../../shared/services/loading.service';
import {
  IPageForm,
  IPollForm,
  IQuestionForm,
} from '../../../../utils/interfaces/poll';
import { TypeOfPollComponent } from './type-of-poll/type-of-poll.component';

@Component({
  selector: 'app-poll-configuration',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    TypeOfPollComponent,
    MatSlideToggleModule,
    MatSliderModule,
    MatSelectModule,
  ],
  providers: [FileService],
  templateUrl: './poll-configuration.component.html',
  styleUrl: './poll-configuration.component.scss',
})
export class PollConfigurationComponent implements OnDestroy {
  private readonly fileService = inject(FileService);
  private readonly loadingService = inject(LoadingService);
  form = input.required<FormGroup<IPollForm>>();
  onCreatedAnswer = output<{
    question: FormGroup<IQuestionForm>;
    weight: 1 | 2 | 3 | 4 | null;
    required: boolean;
  }>();

  onCreatedPage = output();
  onDeletePage = output<number>();

  onAddQuestion = output<FormGroup<IPageForm>>();
  onDeleteQuestion = output<{
    page: FormGroup<IPageForm>;
    questionIndex: number;
  }>();

  private readonly unsubscribe$ = new Subject();

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  uploadImage(file: File): void {
    this.fileService
      .uploadImage(file)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.form().controls.endPage.controls.img.setValue(res.data);
        this.loadingService.closeLoading();
      });
  }

  resizeImage(event: Event): void {
    const target = event.target as HTMLInputElement;
    const file = (target.files as FileList)[0];

    if (!file) return;
    this.loadingService.showLoading();

    this.fileService
      .resizeImage(file)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((newFile) => {
        this.uploadImage(newFile);
      });
  }
}
