import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-quantity-answers',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './quantity-answers.component.html',
  styleUrl: './quantity-answers.component.scss',
})
export class QuantityAnswersComponent {
  count = input.required<FormControl<number | null>>();
}
