import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { AlertComponent } from './shared/components/alert/alert.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { LoadingService } from './shared/services/loading.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, AlertComponent, LoadingComponent],
  template: `
    <app-alert />
    <app-loading />
    <router-outlet />
  `,
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly router = inject(Router);
  private readonly loadingService = inject(LoadingService);

  private readonly unsubscribe$ = new Subject();

  ngOnInit(): void {
    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe(() => setTimeout(() => this.loadingService.showLoading(), 0));

    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => setTimeout(() => this.loadingService.closeLoading(), 0));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
