import { Component, input, model, OnChanges, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatCardModule } from '@angular/material/card';
import { MatCalendar, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { IReportFilter } from '../../../../utils/interfaces/report';

@Component({
  selector: 'app-filter-date',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatDatepickerModule,
    MatIconModule,
  ],
  templateUrl: './filter-date.component.html',
  styleUrl: './filter-date.component.scss',
})
export class FilterDateComponent implements OnChanges {
  selected = model<any>();
  selected2 = model<any>(new Date().toISOString().split('T')[0]);
  startDate = model<Date | null>(null);
  endDate = model<Date | null>(null);

  filter = input.required<IReportFilter>();

  @ViewChild('calendarStartDate') calendar: MatCalendar<Date> | undefined;
  @ViewChild('calendarEndDate') calendar2: MatCalendar<Date> | undefined;

  ngOnChanges(): void {
    if (this.filter().startDate && this.filter().finishDate) {
      this.selected.set(this.filter().startDate?.split('T')[0]);
      this.selected2.set(this.filter().finishDate?.split('T')[0]);
    } else {
      const today = new Date();
      const pastDate = new Date(today.setDate(today.getDate() - 30));

      this.selected.set(today.toISOString().split('T')[0]);
      this.selected2.set(new Date().toISOString().split('T')[0]);
      this.startDate.set(pastDate);
    }

    this.formatData();
  }

  formatData() {
    const splitDate = this.selected().split('-');

    if (
      splitDate.length === 3 &&
      +splitDate[0] > 2000 &&
      +splitDate[0] < 3000
    ) {
      this.startDate.set(
        new Date(+splitDate[0], +splitDate[1] - 1, +splitDate[2])
      );

      if (this.calendar) {
        this.calendar.activeDate = new Date(
          +splitDate[0],
          +splitDate[1] - 1,
          +splitDate[2]
        );
      }
    }

    const splitDate2 = this.selected2().split('-');

    if (
      splitDate2.length === 3 &&
      +splitDate2[0] > 2000 &&
      +splitDate2[0] < 3000
    ) {
      this.endDate.set(
        new Date(+splitDate2[0], +splitDate2[1] - 1, +splitDate2[2])
      );

      if (this.calendar2) {
        this.calendar2.activeDate = new Date(
          +splitDate2[0],
          +splitDate2[1] - 1,
          +splitDate2[2]
        );
      }
    }

    this.filter().startDate = this.startDate()?.toISOString()!;
    this.filter().finishDate = this.endDate()?.toISOString()!;
  }

  selectDate() {
    if (this.startDate())
      this.selected.set(this.startDate()?.toISOString().split('T')[0]);
    if (this.endDate())
      this.selected2.set(this.endDate()?.toISOString().split('T')[0]);

    this.filter().startDate = this.startDate()?.toISOString()!;
    this.filter().finishDate = this.endDate()?.toISOString()!;
  }
}
