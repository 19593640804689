<h2>Usuários</h2>
<div class="card">
  <div class="card-header">
    <div class="inputs">
      <div class="input-icon">
        <input
          type="text"
          class="form-control"
          placeholder="Buscar"
          [formControl]="searchUser"
        />
        <img src="assets/svg/search.svg" alt="search" />
      </div>

      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <mat-select [formControl]="userInactive">
          <mat-option [value]="null">Todos</mat-option>
          <mat-option [value]="true">Ativos</mat-option>
          <mat-option [value]="false">Desativados</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <button class="btn danger" routerLink="../new-user">
      Criar novo usuário
    </button>
  </div>

  <app-table
    [tableHeader]="tableHeader"
    [list]="list"
    [actionList]="actionList"
    (onChangeStatus)="changeStatus($event)"
    (onSelectAction)="selectAction($event)"
    imgForActions="assets/img/edit.png"
  />

  @if (countUsers) {
  <app-pagination
    [total]="countUsers"
    [value]="pagination"
    [showRowsSelect]="true"
    (onPaginationChange)="onPaginationChange($event)"
  />

  }
</div>
