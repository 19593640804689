import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap, tap } from 'rxjs';

import { MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';

import { environment } from '../../../environments/environment';
import { IResponse } from '../../utils/interfaces/response';
import { SessionTokenService } from './session-token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly URI_API = environment.apiConfig.uri + 'Login';

  constructor(
    readonly msalService: MsalService,
    readonly sessionTokenService: SessionTokenService,
    private readonly httpClient: HttpClient
  ) {
    this.msalService.initialize();
  }

  loginOffice365(): Observable<any> {
    const loginRequest = {
      scopes: ['user.read'],
    };
    return this.msalService
      .loginPopup(loginRequest)
      .pipe(switchMap((res) => this.getAuthorizationToken(res)));
  }

  logoutOffice365(): Observable<void> {
    return this.msalService.logoutPopup();
  }

  isLoggedOffice365(): AccountInfo {
    return this.msalService.instance.getAllAccounts()[0] || null;
  }

  getAuthorizationToken(
    authenticationOffice365: AuthenticationResult
  ): Observable<IResponse<{ accessToken: string }>> {
    return this.httpClient
      .post<IResponse<{ accessToken: string }>>(
        `${this.URI_API}/authenticate`,
        {
          token: authenticationOffice365.accessToken,
        }
      )
      .pipe(
        tap((res) =>
          this.sessionTokenService.setToken(res.data.accessToken, true)
        )
      );
  }

  login(
    email: string,
    password: string
  ): Observable<IResponse<{ accessToken: string }>> {
    return this.httpClient
      .post<IResponse<{ accessToken: string }>>(`${this.URI_API}`, {
        email,
        password,
      })
      .pipe(
        tap((res) => this.sessionTokenService.setToken(res.data.accessToken))
      );
  }
}
