<h2>Clientes</h2>
<div class="card">
  <div class="input-icon">
    <input
      type="text"
      class="form-control"
      placeholder="Buscar"
      [formControl]="searchText"
    />
    <img src="assets/svg/search.svg" alt="search" />
  </div>

  <app-table [tableHeader]="tableHeader" [list]="list" />

  <app-pagination
    [total]="itemCount"
    [value]="{ page: pagination.page, take: pagination.take }"
    [showRowsSelect]="true"
    (onPaginationChange)="onPaginationChange($event)"
  />
</div>
