<div class="dialog-menu">
  <div
    class="menu-item first"
    [ngClass]="{ active: filter().responseDevice === 0 }"
    (click)="filter().responseDevice = 0"
  >
    Todos
  </div>
  <div
    class="menu-item"
    [ngClass]="{ active: filter().responseDevice === 1 }"
    (click)="filter().responseDevice = 1"
  >
    Totens/dispositivos
  </div>
  <div
    class="menu-item last"
    [ngClass]="{ active: filter().responseDevice === 2 }"
    (click)="filter().responseDevice = 2"
  >
    Pesquisa online
  </div>
</div>

<mat-radio-group
  aria-labelledby="example-radio-group-label"
  class="example-radio-group"
  [(ngModel)]="filter().responseType"
>
  <mat-radio-button class="example-radio-button" [value]="0">
    Todos
  </mat-radio-button>
  <mat-radio-button class="example-radio-button" [value]="1">
    Incompletas
  </mat-radio-button>
  <mat-radio-button class="example-radio-button" [value]="2">
    Completas
  </mat-radio-button>
</mat-radio-group>
