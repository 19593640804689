import { Component, inject } from '@angular/core';
import {
  FormArray,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { CommonModule } from '@angular/common';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { IPollForm, IQuestionForm } from '../../../../utils/interfaces/poll';
import { PreviewPollComponent } from '../../preview-poll/preview-poll.component';

@Component({
  selector: 'app-page-assignment',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatSelectModule,
  ],
  templateUrl: './page-assignment.component.html',
  styleUrl: './page-assignment.component.scss',
})
export class PageAssignmentComponent {
  readonly dialogRef = inject(MatDialogRef<PageAssignmentComponent>);
  readonly data = inject<{ form: FormGroup<IPollForm>; formValue: any }>(
    MAT_DIALOG_DATA
  );
  private readonly dialog = inject(MatDialog);

  onNoClick(): void {
    this.data.form.setValue(this.data.formValue);

    this.dialogRef.close();
  }

  questionTypeOne(
    questions: FormArray<FormGroup<IQuestionForm>>
  ): FormGroup<IQuestionForm> | undefined {
    return questions.controls.find((q) => q.value.type === 1);
  }

  preview() {
    this.dialog.open(PreviewPollComponent, {
      minWidth: 800,
      maxWidth: 800,
      data: {
        formValue: this.data.form.getRawValue(),
      },
    });
  }
}
