<h6 mat-dialog-title>{{ data.title }}</h6>
<mat-dialog-content>{{ data.description }}</mat-dialog-content>
<mat-dialog-actions>
  <button class="btn danger__no-bg" mat-dialog-close>
    @if (data.btnCancel) {
    {{ data.btnCancel }}
    }@else { Cancelar }
  </button>
  <button class="btn danger" [mat-dialog-close]="true">
    @if (data.btnConfirm) {
    {{ data.btnConfirm }}
    }@else { Sim }
  </button>
</mat-dialog-actions>
