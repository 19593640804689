import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, Subject, takeUntil } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { ConfirmationModalComponent } from '../../../shared/components/confirmation-modal/confirmation-modal.component';
import {
  IPaginationValue,
  PaginationComponent,
} from '../../../shared/components/pagination/pagination.component';
import { TableComponent } from '../../../shared/components/table/table.component';
import { AlertService } from '../../../shared/services/alert.service';
import { LoadingService } from '../../../shared/services/loading.service';
import { PollService } from '../../../shared/services/poll.service';
import { IPollToList } from '../../../utils/interfaces/poll';
import { ITableHeader } from '../../../utils/interfaces/table-header';
import { PreviewPollComponent } from '../preview-poll/preview-poll.component';

@Component({
  selector: 'app-list',
  standalone: true,
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    TableComponent,
    PaginationComponent,
  ],
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss',
})
export class ListComponent implements OnInit, OnDestroy {
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly pollService = inject(PollService);
  private readonly loadingService = inject(LoadingService);
  private readonly alertService = inject(AlertService);
  private readonly dialog = inject(MatDialog);
  private readonly router = inject(Router);
  private readonly unsubscribe$ = new Subject();

  tableHeader: ITableHeader[] = [
    {
      description: 'Status',
      propertyKey: 'status',
      status: true,
      date: false,
      actions: false,
      filter: false,
    },
    {
      description: 'Pesquisas',
      propertyKey: 'title',
      status: false,
      date: false,
      actions: false,
      filter: false,
    },
    {
      description: 'Empresa',
      propertyKey: 'company',
      status: false,
      date: false,
      actions: false,
      filter: false,
    },
    {
      description: 'N respostas',
      propertyKey: 'NRespostas',
      status: false,
      date: false,
      actions: false,
      filter: false,
    },
    {
      description: 'Última avaliação',
      propertyKey: 'validationDate',
      status: false,
      date: true,
      actions: false,
      filter: false,
    },
    {
      description: 'Ações',
      propertyKey: '',
      status: false,
      date: false,
      actions: true,
      filter: false,
    },
  ];

  list: IPollToList[] = [];

  actionList: string[] = ['Duplicar', 'Visualizar', 'Excluir'];

  countPoll = 0;
  pagination: IPaginationValue = {
    page: 1,
    take: 10,
  };

  searchString = new FormControl<string>('');
  searchStatus = new FormControl<boolean | null>(null);

  ngOnInit(): void {
    const { count, data } = this.activatedRoute.snapshot.data['polls'];
    this.countPoll = count ?? 0;
    this.list = data;

    this.searchString.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(debounceTime(700))
      .subscribe(() => {
        this.loadingService.showLoading();
        this.getPolls(true);
      });

    this.searchStatus.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.loadingService.showLoading();
        this.getPolls(true);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  changeStatus(id: string): void {
    this.loadingService.showLoading();

    this.pollService
      .updatePollStatus(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.loadingService.closeLoading();
        this.alertService.success('Status da pesquisa alterado com sucesso');
      });
  }

  selectAction(item: { id: string; func: string }) {
    if (item.func === 'Duplicar') {
      this.duplicatePoll(item.id);
    } else if (item.func === 'Visualizar') {
      this.viewPoll(item.id);
    } else if (item.func === 'Excluir') {
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: '500px',
        data: {
          title: 'Excluir pesquisa',
          description: 'Tem certeza que deseja excluir essa pesquisa?',
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result) => {
          if (result) this.deletePoll(item.id);
        });
    }
  }

  duplicatePoll(id: string): void {
    this.loadingService.showLoading();

    this.pollService
      .duplicatePoll(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.loadingService.closeLoading();
        this.alertService.success('Pesquisa duplicada com sucesso');

        void this.router.navigate(['main/polls/edit-poll', res.data]);
      });
  }

  onPaginationChange(event: IPaginationValue): void {
    this.pagination = event;

    this.getPolls();
  }

  getPolls(search = false): void {
    this.pollService
      .getPolls(
        search,
        this.searchString.value ?? '',
        this.pagination.page,
        this.pagination.take,
        this.searchStatus.value
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.list = res.data;
        this.countPoll = res.count ?? 0;
        this.loadingService.closeLoading();

        if (search) {
          this.pagination.page = 1;
        }
      });
  }

  deletePoll(id: string): void {
    this.loadingService.showLoading();

    this.pollService
      .deletePoll(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getPolls();

        this.alertService.success('Pesquisa excluída com sucesso');
      });
  }

  viewPoll(id: string): void {
    this.loadingService.showLoading();

    this.pollService
      .getPollById(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.loadingService.closeLoading();
        this.dialog.open(PreviewPollComponent, {
          minWidth: 800,
          maxWidth: 800,
          data: {
            formValue: res.data,
          },
        });
      });
  }
}
