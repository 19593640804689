<div class="days-week">
  @for (day of weekDay; track $index) {
  <div
    class="day"
    [ngClass]="{ active: filter().weekDay === day.id }"
    (click)="selectDayWeek(day.id)"
  >
    {{ day.day }}
  </div>
  }
</div>
