import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { IPoll } from '../../../utils/interfaces/poll';
import { IUnit } from '../../../utils/interfaces/unit';
import { ReportFiltersComponent } from '../report-filters/report-filters.component';

@Component({
  selector: 'app-report-data',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './report-data.component.html',
  styleUrl: './report-data.component.scss',
})
export class ReportDataComponent implements OnInit {
  private readonly dialog = inject(MatDialog);
  poll!: IPoll;
  units: IUnit[] = [];

  private readonly activatedRoute = inject(ActivatedRoute);

  ngOnInit(): void {
    this.poll = this.activatedRoute.snapshot.data['poll']?.data;
    this.units = this.activatedRoute.snapshot.data['unitUserPoll']?.data;

    this.openFilterDialog();
  }

  openFilterDialog(): void {
    this.dialog.open(ReportFiltersComponent, {
      minWidth: 865,
      maxWidth: 865,
      data: {
        poll: this.poll,
        units: this.units,
      },
    });
  }
}
