import { Component, inject } from '@angular/core';

import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [],
  template: `
    @if (loadingService.getLoadingStatus()) {
    <div class="overlay">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="margin: auto; display: block"
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <g transform="rotate(0 50 50)">
          <rect
            x="47"
            y="17.5"
            rx="3"
            ry="3"
            width="6"
            height="15"
            fill="#941519"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.875s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(45 50 50)">
          <rect
            x="47"
            y="17.5"
            rx="3"
            ry="3"
            width="6"
            height="15"
            fill="#941519"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.75s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(90 50 50)">
          <rect
            x="47"
            y="17.5"
            rx="3"
            ry="3"
            width="6"
            height="15"
            fill="#941519"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.625s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(135 50 50)">
          <rect
            x="47"
            y="17.5"
            rx="3"
            ry="3"
            width="6"
            height="15"
            fill="#941519"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.5s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(180 50 50)">
          <rect
            x="47"
            y="17.5"
            rx="3"
            ry="3"
            width="6"
            height="15"
            fill="#941519"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.375s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(225 50 50)">
          <rect
            x="47"
            y="17.5"
            rx="3"
            ry="3"
            width="6"
            height="15"
            fill="#941519"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.25s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(270 50 50)">
          <rect
            x="47"
            y="17.5"
            rx="3"
            ry="3"
            width="6"
            height="15"
            fill="#941519"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="-0.125s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(315 50 50)">
          <rect
            x="47"
            y="17.5"
            rx="3"
            ry="3"
            width="6"
            height="15"
            fill="#941519"
          >
            <animate
              attributeName="opacity"
              values="1;0"
              keyTimes="0;1"
              dur="1s"
              begin="0s"
              repeatCount="indefinite"
            ></animate>
          </rect>
        </g>
      </svg>
    </div>
    }
  `,
  styles: [
    `
      .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `,
  ],
})
export class LoadingComponent {
  readonly loadingService = inject(LoadingService);
}
