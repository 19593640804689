import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';

import { ICustomer } from '../../../utils/interfaces/customer';
import { IResponse } from '../../../utils/interfaces/response';
import { CustomersService } from '../customers.service';

export const getCustomersResolver: ResolveFn<
  Observable<IResponse<ICustomer[]>>
> = (route, state) => {
  const customersService = inject(CustomersService);
  return customersService.getCustomers(false, '', 1, 10);
};
