<div class="type-form">
  <div [ngClass]="firstPage() ? 'first-page' : 'no-first-page'">
    <mat-form-field>
      <mat-label>Título da pergunta</mat-label>
      <input
        matInput
        placeholder="Ex. Qual a sua opinião sobre o serviço de hoje?"
        maxlength="128"
        [formControl]="formQuestion().controls.title"
      />
      @if (formQuestion().controls.title.hasError('required')) {
      <mat-error>Título da pergunta é obrigatório</mat-error>
      }
    </mat-form-field>
  </div>

  @if (!firstPage()) {
  <div class="select-type">
    <mat-form-field appearance="outline">
      <mat-label>Tipo de pergunta</mat-label>
      <mat-select [formControl]="formQuestion().controls.type">
        <mat-option [value]="1" (click)="changeQuestionType(1)"
          >Seleção única</mat-option
        >
        <mat-option [value]="2" (click)="changeQuestionType(2)"
          >Multi seleção</mat-option
        >
        <mat-option [value]="3" (click)="changeQuestionType(3)"
          >Campo de texto</mat-option
        >
      </mat-select>
      @if (formQuestion().controls.type.hasError('required')) {
      <mat-error>Tipo de pergunta é obrigatório</mat-error>
      }
    </mat-form-field>
  </div>
  }
</div>

<mat-form-field class="description">
  <mat-label>Descrição da pergunta</mat-label>
  <input
    matInput
    placeholder="Ex. Opinião sobre o serviço de hoje"
    maxlength="128"
    [formControl]="formQuestion().controls.description"
  />
  @if (formQuestion().controls.description.hasError('required')) {
  <mat-error>Descrição da pergunta é obrigatório</mat-error>
  }
</mat-form-field>

@if (formQuestion().controls.type.value === 1) {
<app-selection
  [formQuestion]="formQuestion()"
  [firstPage]="firstPage()"
  [firstQuestion]="firstQuestion()"
  (onCreatedAnswer)="onCreatedAnswer.emit({
  question: formQuestion(),
  weight: null,
  required: true,
})"
/>

} @else if (formQuestion().controls.type.value === 2) {
<app-selection
  [formQuestion]="formQuestion()"
  [firstPage]="firstPage()"
  [firstQuestion]="firstQuestion()"
  (onCreatedAnswer)="onCreatedAnswer.emit({
  question: formQuestion(),
  weight: null,
  required: true,
})"
/>
} @else if (formQuestion().controls.type.value === 3){<app-text-field
  [formQuestion]="formQuestion()"
/>} @else {
<p class="empty">Selecione o tipo de pergunta</p>
}
