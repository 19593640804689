import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IResponse } from '../../utils/interfaces/response';

@Injectable()
export class FileService {
  private readonly URI_API = environment.apiConfig.uri + 'Image/upload';

  constructor(private readonly httpClient: HttpClient) {}

  uploadImage(file: File): Observable<IResponse<string>> {
    const formData = new FormData();
    formData.append('file', file);

    return this.httpClient.post<IResponse<string>>(`${this.URI_API}`, formData);
  }

  resizeImage(
    file: File,
    maxWidth: number = 100,
    maxHeight: number = 100
  ): Observable<File> {
    return new Observable<File>((observer) => {
      const image = new Image();
      const reader = new FileReader();

      reader.onload = (event: any) => {
        image.src = event.target.result;

        image.onload = () => {
          const canvas = document.createElement('canvas');
          let width = image.width;
          let height = image.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx?.drawImage(image, 0, 0, width, height);

          canvas.toBlob((blob) => {
            if (blob) {
              observer.next(new File([blob], file.name, { type: file.type }));
              observer.complete();
            } else {
              observer.error(new Error('Image resizing failed.'));
            }
          }, file.type);
        };

        image.onerror = (err) => {
          observer.error(err);
        };
      };

      reader.onerror = (err) => {
        observer.error(err);
      };

      reader.readAsDataURL(file);
    });
  }
}
