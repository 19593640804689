import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { Router, RouterModule } from '@angular/router';
import { AlertService } from './../../shared/services/alert.service';
import { LoadingService } from './../../shared/services/loading.service';

import { MatInputModule } from '@angular/material/input';
import { UserService } from '../../shared/services/user.service';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatInputModule,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent implements OnDestroy {
  private readonly userService = inject(UserService);
  private readonly route = inject(Router);
  private readonly alertService = inject(AlertService);
  private readonly loadingService = inject(LoadingService);
  private readonly unsubscribe$ = new Subject();

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  onSubmit() {
    this.loadingService.showLoading();

    this.userService
      .forgotPassword(this.form.value.email!)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.alertService.success(res.message);
        this.loadingService.closeLoading();

        this.route.navigate(['/auth']);
      });
  }
}
